import { Button, Flex, Typography } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';

interface Props<T> {
  options: {
    value: T;
    label: string;
  }[];
  value: T;
  onChange: (value: T) => void;
  title?: string;
  className?: string;
}

function Selector<T extends string>({ options, onChange, value, title, className }: Props<T>) {
  return (
    <Flex align="center" className={className}>
      {title && (
        <Typography.Text className="font-light mr-3" type="secondary">
          {title}
        </Typography.Text>
      )}
      {options.map(option => (
        <Button
          className="mr-2"
          key={option.value}
          type={value === option.value ? 'primary' : 'dashed'}
          onClick={() => onChange(option.value)}
        >
          {capitalize(option.label)}
        </Button>
      ))}
    </Flex>
  );
}

export default Selector;
