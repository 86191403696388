import useURLParams from '@Hooks/useURLParams';
import { useGetEntity } from '@Services/search';
import { GlobalSearch } from '@Shared';
import { AntdIcon, Panel, Table } from '@Shared/Style';
import Country from '@Shared/Style/Country';
import { CAREER_STAGE_MAPPER } from '@Utils/constants';
import { Divider, Flex, Image, Typography } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import TagPanel from '../../../shared/Style/TagPanel/TagPanel';
import MoodPanel from '@Shared/MoodPanel/MoodPanel';
import { generateCmUrl } from '@Utils';

const EditTagAndMood = () => {
  const {
    setParams,
    params: { q },
  } = useURLParams();

  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <>
      <GlobalSearch
        types={['artist', 'track']}
        className="top-search-box"
        icon="edit"
        loading={isFetching}
        onSubmit={q => setParams({ q: q as string })}
        variant="filled"
      />
      <Divider />

      {data?.type === 'artist' && (
        <>
          <Panel title="Artist Info">
            <Flex gap="middle">
              <Image
                src={data.image_url}
                width={150}
                height={150}
                style={{ borderRadius: '50%' }}
              />
              <Flex vertical>
                <Flex align="start" gap="middle">
                  <Typography.Link>{data.name}</Typography.Link> <Country value={data.code2} />
                </Flex>
                Career Stage : {CAREER_STAGE_MAPPER[data.artist_stage.career_stage]}
              </Flex>
            </Flex>
          </Panel>
          <TagPanel entity="artist" id={data.id} tags={data.tags} callback={refetch} />
        </>
      )}
      {data?.type === 'track' && (
        <>
          <Panel title="Track Info">
            <Flex gap="middle">
              <Image src={data.image_url} style={{ width: 100, borderRadius: 4 }} />
              <Flex vertical>
                <Flex align="start" gap="middle">
                  <Typography.Link target="_blank" href={generateCmUrl('track', { id: data.id })}>
                    {data.name}
                  </Typography.Link>
                </Flex>
                <Typography.Text>
                  <AntdIcon name="tag" color="gray" /> ISRC : {data.isrc}
                </Typography.Text>
              </Flex>
            </Flex>
          </Panel>
          <TagPanel
            entity="track"
            id={data.id}
            tags={data.tags.map(({ id, name }) => ({
              name,
              id,
              type: 'main',
              hidden: false,
            }))}
            callback={refetch}
          />
          <MoodPanel id={data.id} moods={data.moods} callback={refetch} />
        </>
      )}
    </>
  );
};

export default EditTagAndMood;
