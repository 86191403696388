import { useGetCampaignHistory } from '@Services/campaign';
import { SearchInput } from '@Shared';
import { DataTable, Panel } from '@Shared/Style';
import { generateInternalLink, getFormattedLocalDate, getDateFormatFromEpoch } from '@Utils';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const CampaignHistory = () => {
  const { data, isLoading } = useGetCampaignHistory();
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleSearch = filteredData => {
    setFilteredData(filteredData);
  };

  return (
    <Panel
      title="Campaign History"
      description="The campaign is a feature that offers discounts through special plans or coupons. In the case of providing coupons, the end date in the table below indicates the expiration date of the coupon. If coupons are not provided, it indicates the next billing date of the plan."
    >
      <SearchInput originalData={data} onChange={handleSearch} />
      <br />
      <DataTable
        data={data}
        loading={isLoading}
        columns={[
          {
            Header: 'User',
            accessor: 'user_info',
            Cell: ({ value, original }) => (
              <Link
                to={generateInternalLink('user/user-profile', {
                  id: value,
                })}
              >
                {original['email'] ?? value}
              </Link>
            ),
          },
          {
            Header: 'Plan Type',
            accessor: 'plan_type',
          },
          {
            Header: 'Start Date',
            accessor: 'start_at',
            Cell: ({ value }) => getFormattedLocalDate(value),
          },
          {
            Header: 'End Date',
            accessor: 'end_at',
            Cell: ({ value }) => getFormattedLocalDate(value),
          },
          {
            Header: 'Days Left',
            Cell: ({ original }) => {
              const endAt = new Date(original['end_at']).getTime();
              const now = new Date().getTime();
              const diff = endAt - now;
              const days = Math.floor(diff / (1000 * 60 * 60 * 24));
              return days >= 0 ? days + ' days' : 'Expired';
            },
          },
          {
            Header: 'Price Name',
            accessor: 'price_name',
          },
          {
            Header: 'Price ID',
            accessor: 'price_id',
          },
          {
            Header: 'Coupon',
            accessor: 'coupon_id',
            Cell: ({ value, original }) => (value ? `${original['coupon_name']} (${value})` : ''),
          },
        ]}
      />
    </Panel>
  );
};

export default CampaignHistory;
