import { Panel, WhatsThis } from '@Shared/Style';
import FAIcon from '@Shared/Style/FAIcon';
import { Divider, Flex, Typography } from 'antd';
import React from 'react';
import InputSuggestion from '@Shared/InputSuggestion/InputSuggestion';
import { useGetKeywordCounts, useGetKeywordSuggestions } from '@Services/keywords';
import Playlists from './Playlists';
import Artists from './Artists';
import Tracks from './Tracks';
import useURLParams from '@Hooks/useURLParams';

const KeywordSearch = () => {
  const {
    params: { q },
    setParams,
  } = useURLParams();
  const { data: suggestions, isLoading } = useGetKeywordSuggestions(
    {
      data: {
        q: q && q.replaceAll(' ', '').toLowerCase(),
        limit: 50,
        offset: 0,
      },
    },
    {
      disable: !q,
    }
  );

  const { data: counts } = useGetKeywordCounts(
    {
      data: {
        q: q && q.replaceAll(' ', '').toLowerCase(),
      },
    },
    {
      disable: !q,
    }
  );

  return (
    <Flex vertical gap="small">
      <InputSuggestion
        defaultValue={q}
        loading={isLoading}
        onSearch={key => setParams({ q: String(key) })}
        renderItem={data => <div>{data.label}</div>}
        suggestions={
          suggestions?.map(e => ({
            value: e,
            label: e,
          })) ?? []
        }
        variant="filled"
        size="large"
        className="max-w-[700px]"
        placeholder="Search for a keyword"
        prefix={<FAIcon name="farSquareK" color="gray" />}
      />

      <Flex vertical gap="small">
        <Divider />
        <Typography.Text type="secondary">
          Weekly increase in new playlists made/edited to have this word: {counts?.playlists_diff}
        </Typography.Text>
        <Typography.Title level={4}>
          Entities added to These Playlists Most in Past Week
          <WhatsThis
            size="large"
            title="This will be its own table, with each row being an artist associated with the keyword. They are ranked according to how often then appear on the playlists with this keyword"
          />
        </Typography.Title>
        <Panel.ResponsiveBody>
          <Artists q={q} />
          <Tracks q={q} />
        </Panel.ResponsiveBody>
        <Playlists q={q} />
      </Flex>
    </Flex>
  );
};

export default KeywordSearch;
