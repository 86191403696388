import { ENTITY_ICON } from '@Constants/entity';
import {
  useAddLabelAssociation,
  useChangeHiddenLabel,
  useGetLabelAssociation,
  useSearchLabel,
} from '@Services/label';
import { AntdTable, GlobalSearch } from '@Shared';
import InputSuggestion, { InputSuggestionOption } from '@Shared/InputSuggestion/InputSuggestion';
import { ModalButton, Panel, Table } from '@Shared/Style';
import FAIcon from '@Shared/Style/FAIcon';
import { Button, Checkbox, Divider, Dropdown, Input, Select, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';

const HiddenCell = ({ value: rawValue, original }) => {
  const { execute, isLoading } = useChangeHiddenLabel();
  const [value, setValue] = useState(rawValue);

  useEffect(() => {
    setValue(rawValue);
  }, [rawValue]);

  return (
    <Checkbox
      checked={value}
      onChange={e => {
        setValue(e.target.checked);
        execute({
          data: {
            cm_artist: original.cm_artist,
            label: original.cm_label_id,
            subLabel: original.cm_sublabel_id,
            type: original.type,
            hidden: e.target.checked,
            manual: original.manual,
          },
        });
      }}
    />
  );
};

const COLUMNS = [
  {
    Header: 'Label',
    accessor: 'cm_label',
  },
  {
    Header: 'Label Id',
    accessor: 'cm_label_id',
    width: 100,
  },
  {
    Header: 'SubLabel',
    accessor: 'cm_sublabel',
  },
  {
    Header: 'SubLabel Id',
    accessor: 'cm_sublabel_id',
    width: 100,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 80,
  },
  {
    Header: 'Hidden',
    accessor: 'hidden',
    width: 80,
    Cell: HiddenCell,
  },
  {
    Header: 'Manual',
    accessor: 'manual',
    width: 80,
    Cell: props =>
      Boolean(props.value) ? <FAIcon color={'green'} name={'fasCheckCircle'} /> : <></>,
  },
];

const LabelAssociation = () => {
  const [q, setQ] = useState('');
  const { data, isLoading, refetch } = useGetLabelAssociation(
    {
      data: { q },
    },
    {
      disable: !q,
    }
  );
  const [label, setLabel] = useState<InputSuggestionOption>();
  const [subLabel, setSubLabel] = useState<InputSuggestionOption>();
  const { data: labelSuggestions, isLoading: isLoadingLabelSuggestion } = useSearchLabel(
    {
      data: { label: label?.value as number },
    },
    {
      disable: !label?.value,
    }
  );
  const { data: subLabelSuggestions, isLoading: isLoadingSubLabelSuggestion } = useSearchLabel(
    {
      data: { label: subLabel?.value as number },
    },
    {
      disable: !subLabel?.value,
    }
  );

  const { execute, isLoading: isLoadingAdd } = useAddLabelAssociation();

  const getSuggestionsMap = (suggestions: typeof labelSuggestions) => {
    if (suggestions)
      return Object.entries(suggestions).reduce<{ label: string; value: number }[]>(
        (acc, [key, value]) => {
          acc.push({ label: value.name as string, value: Number(key) });
          return acc;
        },
        []
      );
  };

  const findLabelIdByName = (name: string) => {
    const id = Object.entries({ ...labelSuggestions, ...subLabelSuggestions }).find(
      ([key, value]) => value.name === name
    )?.[0];
    if (id) return Number(id);
  };

  const labelId =
    typeof label?.value === 'number' ? label?.value : findLabelIdByName(label?.value!)!;
  const subLabelId =
    typeof subLabel?.value === 'number' ? subLabel?.value : findLabelIdByName(subLabel?.value!)!;

  return (
    <>
      <GlobalSearch
        variant="filled"
        loading={isLoading}
        types={['artist']}
        onSubmit={value => setQ(value as string)}
      />

      {data && (
        <>
          <Divider />
          <Panel
            icon="farLayerGroup"
            extra={
              <ModalButton
                title="Add Label Association"
                Button={
                  <Button type="primary" icon={<FAIcon name="fasPlus" />} className="green">
                    Add Label Association
                  </Button>
                }
                okButtonProps={{
                  style: { display: 'none' },
                }}
                cancelButtonProps={{
                  style: { display: 'none' },
                }}
              >
                <Table
                  form={{
                    disabled: label?.value === undefined || labelId === subLabelId,
                    loading: isLoadingAdd,
                    onSubmit: values =>
                      execute({
                        data: {
                          ...values,
                          cm_artist: data.cm_artist,
                          label: labelId,
                          subLabel: subLabelId,
                          type: values.type as 'current' | 'prior',
                        },
                      }).then(() => refetch()),
                    submitPosition: 'bottomRight',
                    initialValues: {
                      cm_artist: data.cm_artist,
                      type: 'prior',
                      hidden: false,
                      manual: true,
                    },
                  }}
                  layout="horizontal"
                  options={[
                    {
                      labelIcon: ENTITY_ICON.ARTIST,
                      span: 24,
                      label: 'Artist ID',
                      value: data.cm_artist.toString(),
                    },
                    {
                      labelIcon: 'farTag',
                      span: 24,
                      label: 'Label Name',
                      value: 'label',
                      component: (
                        <InputSuggestion
                          title="Search Results"
                          key="label"
                          loading={isLoadingLabelSuggestion}
                          suggestions={getSuggestionsMap(labelSuggestions) ?? []}
                          renderItem={item => `${item.label} (${item.value})`}
                          onSearch={(value, option) => setLabel(option ?? { label: '', value })}
                          placeholder="Search Label Name or ID"
                        />
                      ),
                    },
                    {
                      labelIcon: 'farTag',
                      span: 24,
                      label: 'Parent Label',
                      value: 'subLabel',
                      component: (
                        <InputSuggestion
                          title="Search Results"
                          key="subLabel"
                          loading={isLoadingSubLabelSuggestion}
                          suggestions={getSuggestionsMap(subLabelSuggestions) ?? []}
                          renderItem={item => `${item.label} (${item.value})`}
                          onSearch={(value, option) => setSubLabel(option ?? { label: '', value })}
                          placeholder="Search Sub Label Name or ID"
                        />
                      ),
                    },
                    {
                      span: 24,
                      label: 'Type',
                      value: 'type',
                      component: (
                        <Select
                          className="w-full"
                          options={[
                            {
                              label: 'Current Association',
                              value: 'current',
                            },
                            {
                              label: 'Prior Association',
                              value: 'prior',
                            },
                          ]}
                        />
                      ),
                    },
                    {
                      span: 24,
                      label: 'Hidden',
                      value: 'hidden',
                      component: <Checkbox />,
                      isCheckbox: true,
                    },
                  ]}
                />
              </ModalButton>
            }
            title="Modifying Associated Label Of Artist"
            description="Manually set current and prior labels for an artist"
          >
            <AntdTable data={data.labels} columns={COLUMNS} />
          </Panel>
        </>
      )}
    </>
  );
};

export default LabelAssociation;
