import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { Divider, Segment, Checkbox, Button, Table, Input, Dropdown } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import * as actions from '../../../../actions';
import {
  getFormattedLocalDate,
  getDateFormatFromEpoch,
  showErrorMessage,
  showInfoMessage,
} from '../../../../utilities';
import SearchInput from '../../../../shared/Table/SearchInput';
import CampaignContentModal from '../Components/CampaignContentModal';
import useModal from '../../../../hooks/useModal';
import { useGetCampaignContentById } from '../../../../services/campaign';
import { DataTable, ModalButton, Panel } from '../../../../shared/Style';
import styles from '../Campaign.module.scss';
import StripeCouponPanel from '../Components/StripeCouponPanel';
import { STRIPE_STANDARD_PLANS } from '../../../../utilities/constants';
import { Flex } from 'antd';

const COUPON_LIST_CACHE_KEY = 'Admin: getStripeCoupons: []';

const COLUMNS_COUPON = (handleSelectCoupon, productId, couponCode, priceItem) => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: props => <b>{props.value}</b>,
    width: 300,
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Discount Amount',
    accessor: 'amount_off',
    Cell: props => (props.value ? `${props.value / 100}$` : ''),
  },
  {
    Header: 'Discount Percent',
    accessor: 'percent_off',
    Cell: props => (props.value ? `${props.value}%` : ''),
  },
  {
    Header: 'Type',
    accessor: 'duration',
    Cell: props =>
      props.value === 'repeating' ? `${props.original.duration_in_months} months` : props.value,
  },
  {
    Header: 'Created At',
    accessor: 'created',
    Cell: props => getDateFormatFromEpoch(props.value),
  },
  {
    Header: 'Applicable Product',
    accessor: 'applies_to.products',
  },
  {
    Header: 'Apply Coupon',
    accessor: 'id',
    Cell: props => {
      // Checking logic whether coupon can be applied to the plan.
      const matchBillingCycle =
        priceItem?.recurring?.interval === 'year' && props.original?.duration === 'repeating'
          ? props.original?.duration_in_months >= 12
          : true;
      const isAppliesTo =
        isEmpty(props.original.applies_to) ||
        props.original.applies_to?.products.includes(productId);
      return (
        isAppliesTo &&
        matchBillingCycle && (
          <Button
            className={props.value === couponCode ? 'green' : ''}
            onClick={handleSelectCoupon(props.value)}
          >
            Select Coupon
          </Button>
        )
      );
    },
  },
];

const COLUMNS_CAMPAIGN = (getPriceItem, getCouponName, deleteCampaign, redirectStripePortal) => [
  {
    Header: 'ID',
    accessor: 'id',
    width: 100,
  },
  {
    Header: 'Domain',
    accessor: 'domain',
    width: 150,
  },
  {
    Header: 'Price Plan',
    accessor: 'price_id',
    Cell: props => {
      const item = getPriceItem(props.value);
      return (
        <span>
          {item.prodNickname} - {item.nickname}
        </span>
      );
    },
  },
  {
    Header: 'Coupon Code',
    accessor: 'coupon_code',
    Cell: props => {
      const name = getCouponName(props.value);
      return (
        <span>
          {name} ({props.value}){' '}
        </span>
      );
    },
  },
  {
    Header: 'Content',
    accessor: 'content',
    width: 100,
    Cell: props => {
      const { showModal, closeModal, modalProps } = useModal();
      const { data: content, fetch } = useGetCampaignContentById();

      useEffect(() => {
        if (modalProps.show) {
          fetch({ id: props.value });
        }
      }, [modalProps.show]);

      return (
        <>
          {props.value && (
            <Button className="blue" onClick={() => showModal()}>
              Content
            </Button>
          )}
          <ModalButton title="Preview Campaign Page" isOpen={modalProps.show} onClose={closeModal}>
            <div className={styles.previewBox}>
              <div className={styles.previewTitle}>
                {content?.title?.replace(':name', 'Chartmetric')}
              </div>
              <br />
              <img
                className={styles.previewImg}
                src={
                  content?.image_url ??
                  'https://storage.googleapis.com/cm-app-assets/images/main/empty-dashboard.png'
                }
              />
              <br />
              <br />
              <div className={styles.previewSentence}>{content?.sentence}</div>
              <br />
              <Button className={styles.previewButton}>Subscribe Now!</Button>
            </div>
          </ModalButton>
        </>
      );
    },
  },
  {
    Header: 'Date',
    accessor: 'timestp',
    Cell: props => getFormattedLocalDate(props.value),
    width: 100,
  },
  {
    Header: 'Delete',
    accessor: 'id',
    Cell: props => (
      <Button className="blue" onClick={() => deleteCampaign(props.value)} icon="delete" />
    ),
    width: 60,
  },
  {
    Header: 'Test Stripe URL',
    accessor: 'id',
    Cell: props => (
      <Button className="green" onClick={() => redirectStripePortal(props.original)}>
        Test Stripe URL
      </Button>
    ),
    width: 150,
  },
];

class Campaign extends Component {
  state = {
    // display state
    enableCoupon: false,
    productId: undefined,
    // parameters
    domain: '',
    priceId: undefined,
    couponCode: undefined,
    content: undefined,
    // result
    url: undefined,
    // table
    filteredDataCouponCode: undefined,
    filteredDataCampaign: undefined,
  };

  componentDidMount() {
    this.props.getStripeProducts();
    this.props.getCampaignList();
    this.props.getStripeCoupons();
  }

  getPlanProductOptions() {
    const { optionData } = this.props;
    return optionData.products
      ?.reduce((all, one) => {
        all.push({
          text: one.name,
          value: one.id,
          label: {
            color: STRIPE_STANDARD_PLANS.includes(one.id) ? 'blue' : 'white',
            empty: true,
            circular: true,
          },
        });
        return all;
      }, [])
      .sort((a, b) => (STRIPE_STANDARD_PLANS.includes(a.value) ? -1 : 1));
  }

  getPlanPriceOptions() {
    const { optionData } = this.props;
    const { productId } = this.state;
    return optionData.prices?.[productId]
      ?.reduce((all, one) => {
        all.push({
          value: one.id,
          text: ` ${one.nickname} - $${one.unit_amount / 100} per ${
            one.recurring?.interval_count
          } ${one.recurring?.interval}`,
          label: {
            color: STRIPE_STANDARD_PLANS.includes(one.id) ? 'blue' : 'white',
            empty: true,
            circular: true,
          },
        });
        return all;
      }, [])
      .sort((a, b) => (STRIPE_STANDARD_PLANS.includes(a.value) ? -1 : 1));
  }

  handleCopy = e => {
    showInfoMessage('Copied!');
    navigator.clipboard.writeText(e.target.id);
  };

  handleSearchCouponCode = filteredData => {
    this.setState({
      filteredDataCouponCode: filteredData,
    });
  };

  handleSearchCampaign = filteredData => {
    this.setState({
      filteredDataCampaign: filteredData,
    });
  };

  handleClick = () => {
    const { domain, couponCode, priceId, content } = this.state;
    this.props.addCampaign({ domain, couponCode, priceId, contentId: content?.id }).then(value => {
      this.props.getCampaignList();
      this.setState({
        // display state
        enableCoupon: false,
        productId: undefined,
        // parameters
        domain: '',
        priceId: undefined,
        couponCode: undefined,
      });
    });
  };

  handleChangeInput = (e, { value, name }) => this.setState({ [name]: value });

  handleChangePlan = (e, { value, name }) => {
    this.setState({ [name]: value, couponCode: undefined });
  };

  handleChangeCouponCheckbox = (e, { checked, name }) => {
    if (!checked) {
      this.setState({ couponCode: undefined });
    }
    this.setState({ [name]: checked });
  };

  handleSelectCoupon =
    ({ id }) =>
    () => {
      this.setState({ couponCode: id });
    };

  handleClickCreateCoupon = () => {
    window.open('https://dashboard.stripe.com/coupons/create', '_blank');
  };

  handleClickRefreshCouponList = () => {
    this.props.deleteCacheKey(COUPON_LIST_CACHE_KEY).then(() => this.props.getStripeCoupons());
  };

  getCouponName = couponId =>
    this.props.couponData.find(coupon => coupon.id === couponId)?.name ?? '';

  getProductItem(productId) {
    return this.props.optionData.products?.find(e => e.id === productId);
  }

  getPriceItem(productId, priceId) {
    return this.props.optionData.prices?.[productId]?.find(e => e.id === priceId);
  }

  checkFormFilled = () => {
    const { domain, enableCoupon, couponCode, productId, priceId } = this.state;
    return isEmpty(domain) || isEmpty(priceId) || (enableCoupon && !couponCode);
  };

  getPriceItemName = priceId => {
    const item = Object.values(this.props.optionData.prices ?? {})
      .flat()
      .find(e => e.id === priceId);
    if (!item) return { nickname: '', prodNickname: '' }; //for local test
    const { name: prodNickname } = this.getProductItem(item.product);
    return { nickname: item.nickname, prodNickname };
  };

  deleteCampaign = id => this.props.deleteCampaign({ id }).then(() => this.props.getCampaignList());

  redirectStripePortal = item => {
    const { coupon_code, price_id, domain } = item;

    this.props.stripeCreateCheckoutSession(
      price_id,
      `hi@${domain}`,
      undefined,
      isEmpty(coupon_code) ? undefined : coupon_code
    );
  };

  handleSelectContent = content => {
    this.setState({ content });
  };

  render() {
    const {
      campaignData,
      couponData,
      isLoadingCoupon,
      isFetching,
      isLoadingOption,
      isLoadingCampaign,
    } = this.props;
    const { couponCode, domain, productId, priceId, content } = this.state;

    return (
      <>
        <Panel
          title="Campaign For Domain"
          description={
            <>
              When a user from a specific domain enters the Campaign page, the event plan is
              automatically applied.
              <a
                href="https://app.chartmetric.com/campaign"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://app.chartmetric.com/campaign
              </a>
            </>
          }
        >
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Email Domain</Table.Cell>
                <Table.Cell>
                  <Input
                    fluid
                    label={{ icon: 'mail' }}
                    name="domain"
                    onChange={this.handleChangeInput}
                    placeholder="ex) chartmetric.com"
                    size="large"
                    value={domain}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Plan</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    loading={isLoadingOption}
                    options={this.getPlanProductOptions()}
                    value={this.state.productId}
                    onChange={this.handleChangePlan}
                    name="productId"
                    floating
                    labeled
                    className="icon"
                    search
                    selection
                    placeholder="Select Product"
                  />
                  <Dropdown
                    loading={isLoadingOption}
                    options={this.getPlanPriceOptions()}
                    value={this.state.priceId}
                    onChange={this.handleChangePlan}
                    name="priceId"
                    floating
                    labeled
                    className="icon"
                    search
                    selection
                    placeholder="Select Price"
                    disabled={!productId}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Coupon</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    name="enableCoupon"
                    onChange={this.handleChangeCouponCheckbox}
                    checked={this.state.enableCoupon}
                    label={`${this.getCouponName(couponCode)} ${
                      couponCode ? `(${couponCode})` : 'Coupon Not selected'
                    }`}
                    toggle
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Page Content</Table.Cell>
                <Table.Cell>
                  {content && (
                    <>
                      <div>Title :{content.title}</div>
                      <div>Sentence : {content.sentence}</div>
                      <div>Image : {content.image_url && <img src={content.image_url} />}</div>
                      <div>
                        Button : {content.button_label && <img src={content.button_label} />}
                      </div>
                      <br />
                    </>
                  )}
                  <CampaignContentModal onSelect={this.handleSelectContent} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider hidden />
          <Flex style={{ width: '100%' }} gap="middle">
            The Stripe plans provided in the campaign will automatically renew once the plan's
            duration has ended. A plan priced at $0 means that once a user subscribes, they can use
            it for free indefinitely. Avoid using the $0 plan and use a coupon instead.
            <Button
              loading={isFetching}
              fluid
              size="large"
              color="green"
              onClick={this.handleClick}
              disabled={this.checkFormFilled()}
            >
              Add New Campaign
            </Button>
          </Flex>
          {this.state.url && (
            <Segment className="text-wrap">
              <a href={this.state.url} target="_blank" rel="noopener noreferrer">
                Generated Link
              </a>{' '}
              :{' '}
              <Button id={this.state.url} className="green" onClick={this.handleCopy}>
                Copy
              </Button>
            </Segment>
          )}
        </Panel>
        <StripeCouponPanel
          selectedCoupon={{ id: couponCode }}
          enableCoupon={this.state.enableCoupon}
          onSelectCoupon={this.handleSelectCoupon}
          priceId={priceId}
          productId={productId}
          products={this.props.optionData.products}
        />
        <Segment loading={isLoadingCampaign}>
          <h3>Campaigns</h3>
          <p>
            After adding the email domain to the Campaign feature, users should log in to the
            Chartmetric website and access https://app.chartmetric.com/campaign.
          </p>
          <SearchInput originalData={campaignData} onChange={this.handleSearchCampaign} />
          <br />
          <DataTable
            key="campaigns"
            data={
              this.state.handleSearchCampaign !== undefined
                ? this.state.filteredDataCampaign
                : campaignData
            }
            columns={COLUMNS_CAMPAIGN(
              this.getPriceItemName.bind(this),
              this.getCouponName.bind(this),
              this.deleteCampaign.bind(this),
              this.redirectStripePortal.bind(this)
            )}
          />
        </Segment>
      </>
    );
  }
}

const mapStateToProps = state => state.purchase;
export default connect(mapStateToProps, actions)(Campaign);
