import React, { useEffect, useRef, useState } from 'react';
import { Flex, Input, Select } from 'antd';
import { isEmpty } from 'lodash';
import useDebounceValue from '@Hooks/useDebounceValue';
import { AntdIcon } from '@Shared/Style';

interface Props<T> {
  onChange?: React.Dispatch<T[]> | ((data: T[]) => void);
  originalData?: T[];
  filters?: { label: string; value: string }[];
  defaultFilter?: string;
  size?: 'small' | 'middle' | 'large';
  variant?: 'filled' | 'outlined' | 'borderless' | undefined;
}

function SearchInput<T extends Record<string, any>>({
  defaultFilter = 'all',
  onChange = () => null,
  originalData = [],
  filters = [],
  size = 'large',
  variant = 'filled',
}: Props<T>) {
  const [input, setInput] = useState<string>();
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const debouncedValue = useDebounceValue(input, 200);
  const prevData = useRef(originalData);

  useEffect(() => {
    if (prevData.current.length !== originalData.length) {
      setInput('');
    }

    if (!isEmpty(originalData)) {
      if (JSON.stringify(originalData) !== JSON.stringify(prevData.current)) {
        prevData.current = originalData;
        const newData = typeof originalData !== 'object' ? [] : originalData;
        onChange(newData);
        if (debouncedValue) onChangeInput(debouncedValue);
      }
    }
  }, [originalData]);

  useEffect(() => {
    if (debouncedValue && !isEmpty(originalData)) {
      onChangeInput(debouncedValue);
    } else {
      const newData = typeof originalData !== 'object' ? [] : originalData;
      onChange(newData);
    }
  }, [debouncedValue, selectedFilter]);

  const onChangeInput = (searchCriteria: string) => {
    const value = searchCriteria;
    if (!originalData || isEmpty(originalData)) return;
    if (value === '') return onChange(originalData);
    const filteredData =
      selectedFilter !== 'all'
        ? originalData.filter(obj =>
            JSON.stringify(obj[selectedFilter])?.toLowerCase().includes(value?.toLowerCase())
          )
        : originalData.filter(obj =>
            Object.values(obj).some(element => {
              if (element === null || element === undefined) return false;
              try {
                return typeof element === 'string' || typeof element === 'number'
                  ? String(element).toLowerCase().includes(value.toLowerCase())
                  : Array.isArray(element)
                  ? element.some(e =>
                      typeof e === 'object'
                        ? JSON.stringify(e).toLowerCase().includes(value.toLowerCase())
                        : String(e).toLowerCase().includes(value.toLowerCase())
                    )
                  : typeof element === 'object'
                  ? JSON.stringify(element).toLowerCase().includes(value.toLowerCase())
                  : false;
              } catch (_) {
                return false;
              }
            })
          );
    onChange(filteredData);
  };
  return (
    <Flex wrap="nowrap" style={{ width: '100%' }} gap="middle">
      {filters.length > 0 && (
        <Select
          style={{ minWidth: '150px' }}
          options={[{ label: 'All', value: 'all' }, ...filters]}
          value={selectedFilter}
          onChange={setSelectedFilter}
          size={size}
          variant={variant}
        />
      )}
      <Input
        variant={variant}
        prefix={<AntdIcon color="lightgrey" name="search" />}
        placeholder="Search"
        value={input as string}
        onChange={({ target: { value } }) => setInput(value)}
        size={size}
        allowClear
      />
    </Flex>
  );
}
export default SearchInput;
