import React, { useEffect, useState } from 'react';
import { Modal, Popconfirm, Button } from 'antd';
import { isEmpty } from 'lodash';
import SearchInput from '../../../shared/Table/SearchInput';

import { useAddTrustedUser, useDeleteTrustedUser, useGetTrustedUser } from '../../../services/user';
import { Panel, DataTable } from '../../../shared/Style';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';
import { generateInternalLink, getFormattedLocalDate } from '@Utils';
import { Link } from 'react-router-dom';
import FAIcon from '@Shared/Style/FAIcon';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';

const columns = (deleteTrustedUser): Column<any>[] => [
  {
    Header: 'User Id',
    accessor: 'user_info', // String-based value accessors!
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
    width: 200,
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: props => (
      <Link
        to={generateInternalLink('user/user-profile', {
          qs: props.value,
        })}
      >
        {props.value}
      </Link>
    ),
  },
  {
    Header: 'Company',
    accessor: 'company',
    filter: true,
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    formatter: value => getFormattedLocalDate(value),
    sorter: true,
  },
  {
    Header: 'Delete',
    width: 50,
    Cell: props => (
      <Popconfirm
        title={
          <span>
            Are you sure delete <strong>{props.original.email}</strong> from trusted user?`
          </span>
        }
        onConfirm={() => deleteTrustedUser(props.original.user_info)}
      >
        <Button icon={<FAIcon name="farTrash" />} className="red" />
      </Popconfirm>
    ),
  },
];

function AddTrustedUserModal({ open, refetch, setOpen }) {
  const { execute: addTrustedUser, isLoading: isLoadingAdd } = useAddTrustedUser();

  const [inputValue, setInputValue] = useState('');

  const handleAddTrustedUser = () => {
    addTrustedUser({ data: { q: inputValue } }).then(() => {
      refetch();
      setOpen(false);
    });
  };

  return (
    <Modal
      title="Add Trusted User"
      open={open}
      onOk={handleAddTrustedUser}
      onCancel={() => setOpen(false)}
      okText="Add"
      okButtonProps={{
        disabled: isEmpty(inputValue),
        loading: isLoadingAdd,
      }}
      cancelText="Close"
    >
      <GlobalSearch
        placeholder="Search for user"
        onChange={setInputValue}
        loading={isLoadingAdd}
        types={['user']}
        icon="user"
        variant="filled"
        size="middle"
      />
    </Modal>
  );
}

function TrustedUsers() {
  const { data, isLoading, refetch } = useGetTrustedUser();
  const { execute: deleteTrustedUser, isLoading: isLoadingDelete } = useDeleteTrustedUser();

  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const onSearch = filteredData => {
    setFilteredData(filteredData);
  };

  const handleDeleteTrustedUser = q => {
    deleteTrustedUser({ data: { q } }).then(() => refetch());
  };

  return (
    <>
      <Panel
        icon="farUserTie"
        title="Trusted User"
        extra={
          <Button
            disabled={isLoading}
            className="green"
            icon={<FAIcon name="farPlus" />}
            onClick={() => setOpen(true)}
          >
            Add Trusted User
          </Button>
        }
      >
        <SearchInput originalData={data} onChange={onSearch} />
        <br />
        <AntdTable
          loading={isLoading || isLoadingDelete}
          data={filteredData ?? []}
          columns={columns(handleDeleteTrustedUser)}
          pageSize={15}
        />
      </Panel>
      <AddTrustedUserModal open={open} refetch={refetch} setOpen={setOpen} />
    </>
  );
}

export default TrustedUsers;
