import React from 'react';
import { Empty, Typography } from 'antd';
import { AntdIcon, DataTable, Panel } from '../../../../shared/Style';
import { generateInternalLink, getFormattedLocalDate } from '@Utils';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';

function ArtistLinksHistory({ artist_links_history }) {
  const columns = [
    {
      Header: 'Social Type',
      accessor: 'type',
      // TODO: add brand Icon
      Cell: ({ value }) => capitalize(value),
    },
    {
      Header: 'Account ID',
      accessor: 'account_id',
    },
    {
      Header: 'URL',
      accessor: 'url',
      Cell: ({ value }) => <Typography.Link href={value}>{value}</Typography.Link>,
    },
    {
      Header: 'User ID',
      accessor: 'user_id',
    },
    {
      Header: 'User Email',
      accessor: 'email',
      Cell: ({ value }) => (
        <Link to={generateInternalLink('user/user-profile', { qs: value })}>{value}</Link>
      ),
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: ({ value }) => getFormattedLocalDate(value),
    },
    {
      Header: 'Active',
      accessor: 'active',
      Cell: ({ value }) => value === 'true' && <AntdIcon name="check" color="green" />,
    },
  ];
  return (
    <Panel title="Artist Links History">
      {!artist_links_history || !artist_links_history.length ? (
        <Empty />
      ) : (
        <DataTable data={artist_links_history} columns={columns} />
      )}
    </Panel>
  );
}

export default ArtistLinksHistory;
