import { ENTITY } from '@Constants/entity';
import Country from '@Shared/Style/Country';
import { generateCmUrl } from '@Utils';
import { Typography, Flex, Tooltip } from 'antd';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import React from 'react';

interface Props {
  value: {
    id: string | number;
    name: string;
    image_url: string;
    platform?: string;
    code2?: string;
    description?: string;
  };
  entity: ENTITY;
}

const EntityCell = ({ value, entity }: Props) => (
  <Tooltip title={value.description}>
    <Typography.Link
      target="_blank"
      href={generateCmUrl(entity, {
        id: value.id,
        service: value.platform,
      })}
    >
      <Flex align="center" gap="small">
        <img
          src={value.image_url}
          width={42}
          height={42}
          className={classNames(
            'hover:scale-110 transform transition-transform duration-300 ease-in-out',
            {
              'rounded-full': entity === 'artist',
              'rounded-md': entity === 'playlist',
            }
          )}
        />
        {capitalize(value.name)}
        {value.code2 && <Country value={value.code2} />}
      </Flex>
    </Typography.Link>
  </Tooltip>
);

export default EntityCell;
