import React, { useState, Component } from 'react';
import { connect } from 'react-redux';
import { Button, Flex, Modal, Popconfirm } from 'antd';
import * as actions from '../../../actions';
import SearchInput from '../../../shared/Table/SearchInput';
import { ModalButton, Panel } from '../../../shared/Style';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';
import { Link } from 'react-router-dom';
import { generateInternalLink, getFormattedLocalDate } from '../../../utilities';
import { useAddAdminUser, useDeleteAdminUser, useGetAdminUsers } from '@Services/user';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import FAIcon from '@Shared/Style/FAIcon';

const DeleteCell = ({ original }) => {
  const { execute, isLoading } = useDeleteAdminUser();
  const { refetch } = useGetAdminUsers();

  return (
    <Flex justify="center">
      <Popconfirm
        title={
          <span>
            Are you sure you want to delete <strong>{original.email}</strong> from admin user?
          </span>
        }
        onConfirm={() =>
          execute({
            data: {
              q: original.user_info,
            },
          }).then(() => refetch())
        }
      >
        <Button loading={isLoading} className="red" icon={<FAIcon name="farTrash" />} />
      </Popconfirm>
    </Flex>
  );
};

const COLUMNS: Column<any>[] = [
  {
    Header: 'User Id',
    accessor: 'user_info', // String-based value accessors!
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: props => (
      <Link
        to={generateInternalLink('user/user-profile', {
          qs: props.value,
        })}
      >
        {props.value}
      </Link>
    ),
  },
  {
    Header: 'Company',
    accessor: 'company',
    filter: true,
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    formatter: value => getFormattedLocalDate(value),
    sorter: true,
  },
  {
    Header: 'Delete',
    Cell: DeleteCell,
  },
];

function AddAdminUserModal() {
  const [inputValue, setInputValue] = useState('');
  const { execute, isLoading } = useAddAdminUser();
  const { refetch } = useGetAdminUsers();

  return (
    <ModalButton
      Button={
        <Button type="primary" icon={<FAIcon name="farPlus" />} className="green">
          Add Admin User
        </Button>
      }
      title="Add Trusted User"
      onOk={() =>
        execute({
          data: {
            q: inputValue,
          },
        }).then(() => refetch())
      }
      okButtonProps={{
        loading: isLoading,
      }}
      okText="Add"
      cancelText="Close"
    >
      <GlobalSearch
        placeholder="Search for user"
        onChange={value => setInputValue(value)}
        loading={isLoading}
        types={['user']}
        icon="user"
        variant="filled"
        size="middle"
      />
    </ModalButton>
  );
}

const AdminUsers = () => {
  const { data, isLoading } = useGetAdminUsers();
  const [filteredData, setFilteredData] = useState<any[]>();

  return (
    <Panel title="Admin Users" icon="farUserGroupCrown" extra={<AddAdminUserModal />}>
      <Flex vertical gap="middle">
        <SearchInput originalData={data} onChange={e => setFilteredData(e)} />
        <AntdTable loading={isLoading} data={filteredData} columns={COLUMNS} pageSize={15} />
      </Flex>
    </Panel>
  );
};

export default AdminUsers;
