import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import { Icon } from '@Shared/Style';

import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from 'react-image-crop';
import { canvasPreview, clearCanvas, getCroppedImage } from './CanvasPreview';

import styles from './ImageCropper.module.scss';
import 'react-image-crop/dist/ReactCrop.css';
import FAIcon from '@Shared/Style/FAIcon';

type Dimensions = { width: number; height: number };

export interface ImageData {
  imageSrc: string | null;
  imageBlob: Blob | null;
}

interface Props {
  defaultImageUrl?: string;
  prompt?: string;
  placeholder?: string;
  disabled?: boolean;
  requiredMinDimensions?: Dimensions;
  maxMBFileSize?: number;
  className?: { container?: string; dropAreadContainer?: string; previewImageWrapper?: string };
  onCropComplete: (imageData: ImageData) => void; // imageSrc will contain Base64 string with an image.
}

const preventDefaults = e => {
  e.preventDefault();
};

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) =>
  centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 80,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );

function ImageCropper({
  prompt,
  placeholder,
  className,
  disabled,
  requiredMinDimensions = {} as Dimensions,
  maxMBFileSize,
  onCropComplete,
  defaultImageUrl,
}: Props) {
  const dropArea = useRef<HTMLDivElement>(null);
  const sourceImageRef = useRef<HTMLImageElement>(null);
  const croppedCanvasRef = useRef(null);
  const blobUrlRef = useRef('');

  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const [imageSrc, setImageSrc] = useState<string>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [disabledCropper, setDisabledCropper] = useState<boolean>(false);

  useEffect(() => {
    if (defaultImageUrl) {
      setImageSrc(defaultImageUrl);
      setDisabledCropper(true);
    }
  }, [defaultImageUrl]);

  const highlight = () => {
    if (dropArea?.current) {
      dropArea.current.classList.add(styles.highlight);
    }
  };

  const unhighlight = () => {
    if (dropArea?.current) {
      dropArea.current.classList.remove(styles.highlight);
    }
  };

  const previewFile = file => {
    const fileSize = round(file.size / 1024 ** 2, 2);

    if (maxMBFileSize && fileSize > maxMBFileSize) {
      setErrorMsg(`File size should be less than ${maxMBFileSize}MB. Received: ${fileSize}}MB`);
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      const image = new Image();
      const readerResult = reader.result?.toString() || '';

      image.onload = () => {
        if (isEmpty(requiredMinDimensions)) {
          setImageSrc(readerResult);
          setErrorMsg('');
        } else if (
          image.width >= requiredMinDimensions.width &&
          image.height >= requiredMinDimensions.height
        ) {
          setImageSrc(readerResult);
          setErrorMsg('');
        } else if (image.width < requiredMinDimensions.width) {
          setErrorMsg(
            `Image width is too small. Min. width: ${requiredMinDimensions.width}px. Received: ${image.width}px`
          );
        } else if (image.height < requiredMinDimensions.height) {
          setErrorMsg(
            `Image height is too small. Min. height: ${requiredMinDimensions.width}px. Received: ${image.height}px`
          );
        } else {
          setErrorMsg('Could not process the image.');
        }
      };

      image.src = readerResult;
    };

    reader.readAsDataURL(file);
  };

  const handleDrop = e => {
    preventDefaults(e);
    if (disabled) return;
    const dt = e.dataTransfer;
    const { files } = dt;
    previewFile(files[0]);
    setDisabledCropper(false);
  };

  const handleFileInputChange = e => {
    preventDefaults(e);
    if (disabled) return;
    const files = e?.target?.files;
    if (files && files.length > 0) {
      setErrorMsg('');
      setDisabledCropper(false);
      previewFile(files[0]);
    }
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    preventDefaults(e);
    const { width, height } = e.currentTarget;
    setImageDimensions({ width, height });
    setCrop(centerAspectCrop(width, height, 1));
  };

  const handleRemovePreview = e => {
    preventDefaults(e);
    setImageSrc(undefined);
    setShowPreview(false);
    setErrorMsg('');

    setCrop(undefined);
    setCompletedCrop(undefined);

    if (croppedCanvasRef.current) {
      clearCanvas(croppedCanvasRef.current);
    }

    onCropComplete({ imageSrc: null, imageBlob: null });
  };

  const handleRemoveCrop = () => {
    const image = sourceImageRef.current;

    setShowPreview(false);

    setCrop(centerAspectCrop(imageDimensions.width, imageDimensions.height, 1));
    if (image)
      setCompletedCrop(
        convertToPixelCrop(
          centerAspectCrop(imageDimensions.width, imageDimensions.height, 1),
          image.width,
          image.height
        )
      );

    if (croppedCanvasRef.current) {
      clearCanvas(croppedCanvasRef.current);
    }
  };

  const handleConfirmCrop = async e => {
    preventDefaults(e);
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      sourceImageRef.current &&
      croppedCanvasRef.current
    ) {
      try {
        // We use canvasPreview as it's much faster than imgPreview.
        await canvasPreview(sourceImageRef.current, croppedCanvasRef.current, completedCrop, 1, 0, {
          ...requiredMinDimensions,
        });

        setErrorMsg('');
        const { imageBlob, imageSrc } = await getCroppedImage(
          sourceImageRef,
          croppedCanvasRef,
          completedCrop,
          blobUrlRef
        );
        onCropComplete({ imageSrc, imageBlob });
        setShowPreview(true);
      } catch (e) {
        setErrorMsg(e.message);
      }
    }
  };

  useEffect(() => {
    if (dropArea?.current) {
      const dropAreaEl: any = dropArea.current;

      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropAreaEl.addEventListener(eventName, preventDefaults, false);
      });

      ['dragenter', 'dragover'].forEach(eventName => {
        dropAreaEl.addEventListener(eventName, highlight, false);
      });

      ['dragleave', 'drop'].forEach(eventName => {
        dropAreaEl.addEventListener(eventName, unhighlight, false);
      });

      dropAreaEl.addEventListener('drop', handleDrop, false);

      return () => {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
          dropAreaEl.removeEventListener(eventName, preventDefaults);
        });

        ['dragenter', 'dragover'].forEach(eventName => {
          dropAreaEl.removeEventListener(eventName, highlight, false);
        });

        ['dragleave', 'drop'].forEach(eventName => {
          dropAreaEl.removeEventListener(eventName, unhighlight, false);
        });

        dropAreaEl.removeEventListener('drop', handleDrop, false);
      };
    }
    return undefined;
  }, [imageSrc]);

  return (
    <div
      className={classNames(className?.container)}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!imageSrc && (
        <div
          className={classNames(styles.dropAreadContainer, className?.dropAreadContainer, {
            [styles.disabled]: disabled,
          })}
          ref={dropArea}
        >
          {placeholder ?? '+ Upload'}
          <input
            type="file"
            className={styles.imgInput}
            accept="image/*"
            onChange={handleFileInputChange}
            disabled={disabled}
          />
        </div>
      )}
      {imageSrc && (
        <div className={classNames(styles.previewImageWrapper, className?.previewImageWrapper)}>
          <div className={styles.buttons}>
            <button
              className={styles.removePreviewIcon}
              onClick={handleRemovePreview}
              title="Remove Image"
              disabled={disabled}
            >
              <FAIcon name="farTrash" />
            </button>
            {!disabledCropper &&
              (showPreview ? (
                <button
                  className={styles.revertCropButton}
                  onClick={handleRemoveCrop}
                  title="Revert Changes"
                  disabled={disabled}
                >
                  <FAIcon name="farRotateLeft" />
                </button>
              ) : (
                <button
                  className={styles.confirmCropButton}
                  onClick={handleConfirmCrop}
                  title="Confirm"
                  disabled={disabled}
                >
                  <FAIcon name="farCheck" />
                </button>
              ))}
          </div>

          {disabledCropper ? (
            <img
              className={styles.previewImageWithoutCrop}
              ref={sourceImageRef}
              src={imageSrc}
              alt="preview"
            />
          ) : (
            <>
              <ReactCrop
                // Important: This component uses functions from utils.ts to generate a cropped image.
                // These functions assume that aspect is 1. Mind this when changing the aspect to a different value.
                aspect={1}
                minWidth={100}
                minHeight={100}
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={crop => setCompletedCrop(crop)}
                keepSelection
                className={classNames({ [styles.hiddenCropper]: showPreview })}
              >
                <img
                  ref={sourceImageRef}
                  src={imageSrc}
                  alt="crop preview"
                  onLoad={handleImageLoad}
                  crossOrigin="anonymous"
                />
              </ReactCrop>
              <canvas
                ref={croppedCanvasRef}
                className={classNames({
                  [styles.hidden]: !showPreview,
                  [styles.previewImage]: showPreview,
                })}
              />
            </>
          )}
        </div>
      )}
      <div className={classNames(styles.prompt, { [styles.isError]: errorMsg.length > 0 })}>
        {errorMsg || (prompt ?? '1080 x 1000 px for optimal display.')}
      </div>
    </div>
  );
}

export default React.memo(ImageCropper);
