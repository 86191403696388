import React, { useMemo } from 'react';

import { AntdIcon } from '@Shared/Style';
import { Button, Flex, Popover, Tooltip, TooltipProps } from 'antd';

interface Props {
  children?: React.ReactNode;
  title?: any;
  color?: string;
  arrow?: 'Hide' | 'Show' | TooltipProps['arrow'];
  size?: 'small' | 'middle' | 'large';
}

/* <WhatsThis />
============================================================================= */
function WhatsThis({ children, title, arrow, size = 'small', color = 'gray' }: Props) {
  const mergedArrow = useMemo<TooltipProps['arrow']>(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <Tooltip
      placement="topLeft"
      title={
        <Flex vertical>
          {title}
          {children}
        </Flex>
      }
      arrow={mergedArrow}
    >
      <Button
        size={size}
        type="text"
        shape="circle"
        icon={<AntdIcon name="questionCircle" color={color} />}
        className="z-50 h-fit py-0 my-0"
      />
    </Tooltip>
  );
}

export default WhatsThis;
