import { usePagination } from '@Hooks';

import { AntdTable } from '@Shared';
import { AntdIcon, BrandIcon, Panel } from '@Shared/Style';
import { addComma } from '@Utils/number';
import { Badge, Button, Flex, Segmented, Select, Switch, Typography } from 'antd';
import { capitalize } from 'lodash';
import React, { useMemo, useState } from 'react';
import COLUMNS from './Components/List/columns';
import { PLATFORM_FILTERS, SUPPORT_PLATFORMS, TABS, VIEWS } from './constants';
import {
  LinkCompletenessOptions,
  useGetLinkCompleteness,
  getLinkCompletenessCacheKey,
  useGetLinkCompletenessStatistics,
} from '@Services/linkCompleteness';
import Statistics from './Components/Statistics/Statistics';
import Filters from './Components/List/Filters';
import TableView from './Components/List/TableView';
import CardView from './Components/List/CardView';
import FAIcon from '@Shared/Style/FAIcon';
import Selector from '@Shared/Style/Selector';

const LinkCompleteness = () => {
  const [entity, setEntity] = useState<(typeof TABS)[number]>('artist');
  const [view, setView] = useState<(typeof VIEWS)[number]>('table');
  const [state, setState] = useState<LinkCompletenessOptions>({
    tier: [1],
    completed: false,
    hasSpotifyURL: undefined,
    hasPandoraURL: undefined,
    hasInstagramURL: undefined,
    hasTiktokURL: undefined,
    hasYoutubeURL: undefined,
    hasShazamURL: undefined,
  });

  const pagination = usePagination({
    defaultLimit: 10,
  });

  const { limit, offset } = pagination;

  const params = {
    path: {
      target: entity,
    },
    data: {
      ...Object.keys(state).reduce((acc, key) => {
        if (state[key] !== 'all') {
          acc[key] = state[key];
        }
        return acc;
      }, {}),

      limit,
      offset,
    } as LinkCompletenessOptions & {
      limit: number;
      offset: number;
    },
  };

  const { data, isLoading } = useGetLinkCompleteness(params, {
    disable: params.data.tier?.length === 0,
  });

  const cacheKey = getLinkCompletenessCacheKey(params);

  const onChangeFilter = (key: string, value: any) => {
    setState(old => ({ ...old, [key]: value }));
  };

  return (
    <>
      <Selector
        className="mb-4"
        options={TABS.map(tab => ({ label: capitalize(tab), value: tab }))}
        value={entity}
        onChange={(value: string) => setEntity(value as any)}
      />
      <Statistics target={entity} state={state} onChangeFilter={onChangeFilter} />
      <Panel
        icon="farBallotCheck"
        title={
          <Flex gap="small" align="center">
            Link Completeness
            <Badge count={addComma(data?.total ?? 0)} color="blue" overflowCount={9999} />
            <Switch
              checkedChildren="Card"
              unCheckedChildren="Table"
              checked={view === 'card'}
              onChange={checked => setView(checked ? 'card' : 'table')}
            />
          </Flex>
        }
        extra={<Filters type={entity} state={state} onChange={onChangeFilter} />}
      >
        <Flex gap="middle">
          {entity === 'artist' ? (
            <>
              <Typography.Text type="secondary" className="flex items-center">
                Verified by admin: <FAIcon name="fasCheckCircle" color="blue" className="ml-1" />
              </Typography.Text>
              <Typography.Text type="secondary" className="flex items-center">
                Has link: <FAIcon name="fasCheckCircle" color="green" className="ml-1" />
              </Typography.Text>
            </>
          ) : (
            <Typography.Text type="secondary">
              Currently, we don't track the user who is updating the track link.
            </Typography.Text>
          )}
        </Flex>
        {view === 'table' ? (
          <TableView
            {...pagination}
            data={data}
            isLoading={isLoading}
            entity={entity}
            cacheKey={cacheKey}
          />
        ) : (
          <CardView
            {...pagination}
            data={data}
            isLoading={isLoading}
            entity={entity}
            cacheKey={cacheKey}
          />
        )}
      </Panel>
    </>
  );
};
export default LinkCompleteness;
