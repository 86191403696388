import React from 'react';
import LabelGroup from '@Features/Label/LabelGroup';
import TwoFactAuthLog from '@Features/Logs/TwoFactAuthLog';
import ArtistIdentityUpdateRequest from '@Features/RequestManager/ArtistIdentityUpdateRequest';
import PushNotificationManager from '@Features/UserManager/PushNotification';
import Claim from '@Features/RequestManager/Claim';
import BlockedDomains from '@Features/Restrictions/BlockedDomains';
import OnesheetUserProfile from '@Features/Onesheet/UserProfile';
import ManageCoupon from '@Features/Purchase/ManageCoupon';
import TeamManager from '@Features/Onesheet/TeamManager';
import TrackArtistRequest from '@Features/RequestManager/TrackArtistRequest';
import AddNewArtist from '../../features/Artist/AddNewArtist';
import MergeArtist from '../../features/Artist/MergeArtist';
import UpdateTrackAndAlbum from '../../features/EditData/EditEntity/Artist/UpdateTrackOrAlbum';
import AddMetadata from '../../features/Artist/AddMetadata';
import EditCMdataPanel from '../../features/EditData/EditEntity';
import DeleteCMdataPanel from '../../features/EditData/DeleteCMdata';
import MergeSocialStat from '../../features/EditData/MergeSocialStat';
import LabelAssociation from '../../features/Label/LabelAssociation';
import LabelCorrection from '../../features/Label/LabelCorrection';
import Campaign from '../../features/Marketing/Campaign/ForDomain';
import CampaignForUser from '../../features/Marketing/Campaign/ForUser';

import ManageTeam from '../../features/Purchase/TeamManager';
import ChurnedUsers from '../../features/Purchase/ChurnedUsers';
import TrialSubscription from '../../features/Purchase/TrialSubscription';
import GenreRequest from '../../features/RequestManager/GenreRequest';
import DSPLinkingRequest from '../../features/RequestManager/DSPLinkingRequest';
import URLUpdateRequest from '../../features/RequestManager/URLUpdateRequest';
import NewsLinkRequest from '../../features/RequestManager/NewsLInkRequest';

import StrictDomains from '../../features/Restrictions/StrictDomains';
import APILimitOverrides from '../../features/Restrictions/APILimitOverrides';
import AddNewTrack from '../../features/Track/AddNewTrack';
import UserProfile from '../../features/UserManager/UserProfile';
import AdminUsers from '../../features/UserManager/AdminUsers';
import TrustedUsers from '../../features/UserManager/TrustedUsers';
import EmailReport from '../../features/UserManager/EmailReport';
import TransferFavorites from '../../features/UserManager/TransferFavorites';
import SignUpHistory from '../../features/UserManager/SignUpHistory';

import DownloadCSV from '../../features/Utility/DownloadCSV';
import ClearCache from '../../features/Utility/ClearCache';
import DeleteStripeInvocies from '../../features/Utility/DeleteStripeInvocies';
import PageHistoryStatistics from '../../features/Utility/PageHistoryStatistics';
import CampaignHistory from '@Features/Marketing/Campaign/History';
import RequestBadge from '../components/Home/RequestBadge';
import LinkCompleteness from '@Features/Logs/LinkCompleteness';
import EditGenre from '@Features/GenreAndMood/EditGenreAndMood';
import EditArtistGenreBatch from '@Features/GenreAndMood/EditArtistGenreBatch';
import EditGenreHierarchy from '@Features/GenreAndMood/EditGenreHierarchy';
import { FAIcons } from '@Shared/Style/FAIcon';
import { ENTITY_ICON } from '@Constants/entity';
import CompanyUnivAccess from '@Features/Marketing/CompanyUnivAccess';
import BulkLinking from '@Features/Linking/BulkLinking';
import KeywordSearch from '@Features/Keywords/KeywordSearch';
import TrendingKeywords from '@Features/Keywords/TrendingKeywords';

export interface MenuItem {
  isNew?: boolean;
  name: string;
  labelSuffix?: React.JSX.Element;
  component: React.JSX.Element;
  icon?: FAIcons;
}

const ITEMS = {
  KEYWORDS: [
    {
      name: 'Trending Keywords',
      component: <TrendingKeywords />,
      icon: 'fadChartLineUp',
      isNew: true,
    },
    {
      name: 'Keyword Search',
      component: <KeywordSearch />,
      icon: 'fadSearch',
      isNew: true,
    },
  ],
  UTILITY: [
    {
      name: 'Download CSV',
      component: <DownloadCSV />,
    },
    {
      name: 'Clear Cache',
      component: <ClearCache />,
    },
    {
      name: 'Delete Stripe Invoices',
      component: <DeleteStripeInvocies />,
    },
  ],
  USER: [
    {
      name: 'User Profile',
      component: <UserProfile />,
      icon: 'farUser',
    },
    {
      name: 'Trusted User',
      icon: 'farUserTie',
      component: <TrustedUsers />,
    },
    {
      name: 'Admin User',
      icon: 'farUserGroupCrown',
      component: <AdminUsers />,
    },

    {
      name: 'Send Email Report',
      icon: 'farEnvelope',
      component: <EmailReport />,
    },
    {
      name: 'Transfer Favorites',
      icon: 'farArrowRightArrowLeft',
      component: <TransferFavorites />,
    },
    {
      name: 'Send Push Notification',
      icon: 'farMobile',
      component: <PushNotificationManager />,
    },
  ],
  TRACK: [
    {
      name: 'Create Track',
      icon: 'farCirclePlus',
      component: <AddNewTrack />,
    },
  ],
  RESTRICTION: [
    {
      name: 'Block Domains',
      component: <BlockedDomains />,
    },
    {
      name: 'Strict Domains',
      component: <StrictDomains />,
    },
    {
      name: 'Public API Limit Overrides',
      component: <APILimitOverrides />,
    },
  ],
  REQUESTS: [
    {
      name: 'DSP Linking',
      labelSuffix: <RequestBadge type="dspLink" />,
      component: <DSPLinkingRequest />,
      icon: 'farLink',
    },
    {
      name: 'URL Change',
      labelSuffix: <RequestBadge type="urlChange" />,
      component: <URLUpdateRequest />,
      icon: 'farLink',
    },
    {
      name: 'Artist Identity Change',
      labelSuffix: <RequestBadge type="artistIdentity" />,
      component: <ArtistIdentityUpdateRequest />,
      icon: 'farVenusMars',
    },
    {
      name: 'News Link',
      labelSuffix: <RequestBadge type="newsLink" />,
      component: <NewsLinkRequest />,
      icon: 'farNewspaper',
    },
    {
      name: 'Custom Genre',
      labelSuffix: <RequestBadge type="customGenre" />,
      component: <GenreRequest />,
      icon: ENTITY_ICON.GENRE,
    },
    {
      name: 'Track Artist Change',
      labelSuffix: <RequestBadge type="trackArtistChange" />,
      component: <TrackArtistRequest />,
      icon: 'farCompactDisc',
    },
    {
      name: 'Claim',
      component: <Claim />,
      icon: 'farHand',
    },
  ],
  PURCHASE: [
    {
      name: 'Churned Users',
      component: <ChurnedUsers />,
    },
    {
      name: 'Trial Subscription',
      component: <TrialSubscription />,
    },
    {
      name: 'Coupon Manager',
      component: <ManageCoupon />,
    },
    {
      name: 'Team Manager',
      icon: 'farPeople',
      component: <ManageTeam />,
    },
  ],
  ONESHEET: [
    {
      name: 'User Profile',
      component: <OnesheetUserProfile />,
      icon: 'farUser',
    },
    {
      name: 'Team Manager',
      component: <TeamManager />,
      icon: 'farPeople',
    },
  ],
  MARKETING: [
    {
      name: 'Company-wide and University Organizations',
      icon: 'farBuildingUser',
      component: <CompanyUnivAccess />,
    },
    {
      name: 'Campaign For Domain',
      component: <Campaign />,
    },
    {
      name: 'Campaign For User',
      component: <CampaignForUser />,
    },
    {
      name: 'Campaign History',
      component: <CampaignHistory />,
    },
  ],
  ARTIST: [
    {
      name: 'Create Artist',
      icon: 'farCirclePlus',
      component: <AddNewArtist />,
    },
    {
      name: 'Merge Duplicated Artist',
      icon: 'farMerge',
      component: <MergeArtist />,
    },
    {
      name: 'Add Artist Metadata',
      icon: 'farPlus',
      component: <AddMetadata />,
    },
  ],
  CM_DATA: [
    {
      name: 'Edit Entity',
      component: <EditCMdataPanel />,
    },
    {
      name: 'Delete Entity',
      component: <DeleteCMdataPanel />,
    },
    {
      name: 'Merge Social Data',
      component: <MergeSocialStat />,
    },
  ],
  TAG: [
    {
      name: 'Edit Genre & Mood',
      component: <EditGenre />,
    },
    {
      name: 'Edit Artist Batch',
      component: <EditArtistGenreBatch />,
      isNew: true,
    },
    {
      name: 'Genre Hierarchy',
      component: <EditGenreHierarchy />,
      isNew: true,
    },
  ],
  LABEL: [
    {
      name: 'Label Group',
      icon: 'farLayerGroup',
      component: <LabelGroup />,
    },
    {
      name: 'Label Association',
      icon: 'farLayerGroup',
      component: <LabelAssociation />,
    },
    {
      name: 'Label Correction',
      component: <LabelCorrection />,
    },
  ],
  LINKING: [
    {
      name: 'Link Completeness',
      component: <LinkCompleteness />,
      icon: 'farBallotCheck',
      isNew: true,
    },
    {
      icon: 'farHistory',
      name: 'Sign Ups For Artist Plan',
      component: <SignUpHistory />,
    },
    {
      name: 'Bulk URL Update',
      component: <BulkLinking />,
      icon: 'farLink',
      isNew: true,
    },
  ],
  LOGS: [
    {
      name: 'Two Factor Authentication Log',
      component: <TwoFactAuthLog />,
    },
    {
      name: 'Page History Statistics',
      component: <PageHistoryStatistics />,
    },
  ],
} satisfies Record<string, MenuItem[]>;

export default ITEMS;