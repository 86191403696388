import { capitalizeEveryWord } from '@Utils';
import { COUNTRY_LIST } from '@Utils/constants';
import { Flex } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';

function Country({ value, origin }: { value: string; origin?: boolean }) {
  if (value)
    return (
      <Flex gap={3} justify="center" align="center" className="text-black">
        <img
          alt={value}
          style={{
            width: 20,
            height: 20,
            borderRadius: 8,
          }}
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value?.toUpperCase()}.svg`}
        />
        {origin ? capitalize(COUNTRY_LIST[value]) : value.toUpperCase()}
      </Flex>
    );
  return null;
}

export default Country;
