import FAIcon from '@Shared/Style/FAIcon';
import { addComma } from '@Utils/number';
import { Flex, Typography, Tag } from 'antd';
import React from 'react';

type Mode = 'diff' | 'percent' | 'value';

const DiffCell = ({
  value,
  diff,
  percent,
  mode,
}: {
  value: number;
  diff: number;
  percent: number;
  mode: Mode;
}) => {
  return (
    <Flex align="center" gap="small" justify="center" vertical>
      <Typography.Text>{addComma(value)}</Typography.Text>
      <Tag
        color={mode === 'percent' && !percent ? 'lightGray' : percent > 0 ? 'green' : 'red'}
        className="flex items-center gap-1 h-[20px]"
      >
        <Flex align="flex-end">
          {mode === 'percent' ? (
            percent ? (
              <>
                <Flex align="center" gap="small" className="mr-1">
                  <FAIcon
                    name={percent > 0 ? 'farArrowUp' : 'farArrowDown'}
                    color={percent > 0 ? 'green' : 'red'}
                  />
                  {(percent * 100)?.toFixed(2)}%
                </Flex>
                ({addComma(diff)})
              </>
            ) : (
              '-'
            )
          ) : (
            <>
              <Flex align="center" gap="small" className="mr-1">
                <FAIcon
                  name={diff > 0 ? 'farArrowUp' : 'farArrowDown'}
                  color={diff > 0 ? 'green' : 'red'}
                />
                {addComma(diff)}
              </Flex>
              ({percent?.toFixed(2)}%)
            </>
          )}
        </Flex>
      </Tag>
    </Flex>
  );
};

export default DiffCell;
