import { SearchInput } from '@Shared';
import { Panel } from '@Shared/Style';
import FAIcon from '@Shared/Style/FAIcon';
import Selector from '@Shared/Style/Selector';
import AntdTable, { Column } from '@Shared/Table/AntdTable';
import { Button, Flex, Image, Segmented, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import EntityOverlapCell from '../../../shared/Table/EntityOverlapCell';
import { addComma, ordinalSuffixFormatter } from '@Utils/number';
import {
  TrendingKeywordsItem,
  TrendingKeywordsSortBy,
  useGetTrendingKeywords,
} from '@Services/keywords';
import { capitalize } from 'lodash';
import { SorterResult } from 'antd/es/table/interface';
import DiffCell from '@Shared/Table/DiffCell';
import Title from '@Shared/Style/Title/Title';
import { CSVLink } from 'react-csv';
import TreeMapChart from './Componetns/TreeMap';
import { Link } from 'react-router-dom';
import { generateInternalLink } from '@Utils';
// TODO: diff and percent are weekly or today?

interface ExtraData {
  mode: Mode;
}

type Mode = 'diff' | 'percent' | 'value';
type Type = 'playlists' | 'followers';

const CSV_FORMAT = [
  { label: 'Position', key: 'position' },
  { label: 'Keyword', key: 'token' },
  { label: 'Playlists', key: 'playlists' },
  { label: 'Followers', key: 'followers' },
  { label: 'Top Artists', key: 'top_artists' },
  { label: 'Top Tracks', key: 'top_tracks' },
  { label: 'Top Playlists by diff', key: 'top_playlists_by_diff' },
  { label: 'Top Playlists by percent diff', key: 'top_playlists_by_percent_diff' },
];

const COLUMN = ({
  sortMode,
  sortedInfo,
}: {
  sortMode: Mode;
  sortedInfo: SorterResult<any>;
}): Column<TrendingKeywordsItem & { position: number }, ExtraData>[] => [
  {
    Header: 'Position',
    accessor: 'position',
    align: 'left',
    sorter: true,
    Cell: ({ value }) => (
      <>
        {value <= 3 && (
          <FAIcon
            name="fasCrown"
            color={value === 1 ? 'orange' : value === 2 ? 'silver' : 'brown'}
          />
        )}
        {ordinalSuffixFormatter(value)}
      </>
    ),
    width: 25,
  },
  {
    Header: (
      <Flex align="center" gap={4}>
        <FAIcon name="farSquareK" />
        <b>Trending Keywords</b>
      </Flex>
    ),
    Cell: ({ value }) => (
      <Flex align="center" gap={4}>
        <Typography.Text className="text-lg" strong>
          {capitalize(value)}
        </Typography.Text>

        <Link
          target="_blank"
          to={generateInternalLink(`keyword/keyword-search`, {
            q: value,
          })}
        >
          <Tooltip title={`Search ${capitalize(value)}`}>
            <Button
              type="text"
              icon={<FAIcon name="farSearch" color="gray" className="w-[12px] h-[12px]" />}
            />
          </Tooltip>
        </Link>
      </Flex>
    ),
    align: 'left',
    accessor: 'TOKEN',
    width: 200,
    search: true,
  },
  {
    key: 'playlists',
    Header: 'Playlists',
    sorter: true,
    accessor: {
      value: 'TOKEN_PLAYLISTS_TODAY',
      percent: 'TOKEN_PLAYLISTS_PERCENT_GROWTH',
      diff: 'TOKEN_PLAYLISTS_DIFF',
    }[sortMode] as keyof TrendingKeywordsItem,
    Cell: ({ original, extraData }) => (
      <DiffCell
        value={original.TOKEN_PLAYLISTS_TODAY}
        diff={original.TOKEN_PLAYLISTS_DIFF}
        percent={original.TOKEN_PLAYLISTS_PERCENT_GROWTH}
        mode={extraData.mode}
      />
    ),
    sortOrder: sortedInfo.columnKey === 'playlists' ? sortedInfo.order : undefined,
    width: 260,
  },
  {
    key: 'followers',
    Header: 'Followers',
    accessor: {
      value: 'TOKEN_FOLLOWERS_TODAY',
      percent: 'TOKEN_FOLLOWERS_PERCENT_GROWTH',
      diff: 'TOKEN_FOLLOWERS_DIFF',
    }[sortMode] as keyof TrendingKeywordsItem,
    Cell: ({ original, extraData }) => (
      <DiffCell
        value={original.TOKEN_FOLLOWERS_TODAY}
        diff={original.TOKEN_FOLLOWERS_DIFF}
        percent={original.TOKEN_FOLLOWERS_PERCENT_GROWTH}
        mode={extraData.mode}
      />
    ),
    sorter: true,
    sortOrder: sortedInfo.columnKey === 'followers' ? sortedInfo.order : undefined,
    width: 260,
  },
  {
    Header: 'Top Artists',
    accessor: 'TOP_ARTISTS',
    Cell: ({ value }) => (
      <EntityOverlapCell
        value={value.map(e => ({
          id: e.cm_artist,
          name: e.artist_name,
          description: `Added to ${addComma(e.adds)} Playlists`,
          image_url: e.image_url,
        }))}
        entity="artist"
      />
    ),
    width: 300,
    search: true,
  },
  {
    Header: 'Top Tracks',
    accessor: 'TOP_TRACKS',
    Cell: ({ value }) => (
      <EntityOverlapCell
        value={value.map(e => ({
          id: e.cm_track,
          name: e.track_name,
          description: `Added to ${addComma(e.adds)} Playlists`,
          image_url: e.image_url,
        }))}
        entity="track"
      />
    ),
    search: true,
    width: 300,
  },
  {
    Header: 'Top Playlists by diff',
    accessor: 'TOP_PLAYLISTS_BY_FOLLOWERS_DIFF_RANK',
    Cell: ({ value }) => (
      <EntityOverlapCell
        value={value.map(e => ({
          id: e.spotify_playlist,
          name: e.name,
          description: `Followers Diff: ${addComma(e.followers_diff)}`,
          image_url: e.image_url,
          platform: 'spotify',
        }))}
        entity="playlist"
      />
    ),
    width: 300,
    search: true,
  },
  {
    Header: 'Top Playlists by percent diff',
    accessor: 'TOP_PLAYLISTS_BY_FOLLOWERS_PERCENT_DIFF_RANK',
    Cell: ({ value }) => (
      <EntityOverlapCell
        value={value.map(e => ({
          id: e.spotify_playlist,
          name: e.name,
          description: `Followers Diff: ${addComma(e.followers_diff)}`,
          image_url: e.image_url,
          platform: 'spotify',
        }))}
        entity="playlist"
      />
    ),
    search: true,
    width: 300,
  },
];

const TrendingKeywords = () => {
  const [mode, setMode] = useState<Mode>('value');
  const [type, setType] = useState<Type>('playlists');
  const lastMonday = dayjs().startOf('week').format('MMM Do');
  const today = dayjs().format('MMM Do');

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [sortMode, setSortMode] = useState<Mode>('value');

  const { data, isLoading, isFetched } = useGetTrendingKeywords({
    data: {
      limit: 50,
      offset: 0,
      sortBy: mode === 'value' ? type : (`${type}_${mode}` as TrendingKeywordsSortBy),
      sortOrderDesc: true,
    },
  });

  useEffect(() => {
    if (isFetched) {
      setSortedInfo({
        columnKey: type,
        order: 'descend',
      });
      setSortMode(mode);
    }
  }, [isFetched, mode, type]);

  const columns = useMemo(() => COLUMN({ sortMode, sortedInfo }), [sortMode, sortedInfo]);

  const getCSVData = () => {
    if (!data) return [];
    return data.map((e, index) => ({
      position: index + 1,
      token: e.TOKEN,
      playlists: `${e.TOKEN_PLAYLISTS_TODAY} (${e.TOKEN_PLAYLISTS_DIFF})`,
      followers: `${e.TOKEN_FOLLOWERS_TODAY} (${e.TOKEN_FOLLOWERS_DIFF})`,
      top_artists: e.TOP_ARTISTS.map(e => `${e.artist_name} (${e.cm_artist})`).join(', '),
      top_tracks: e.TOP_TRACKS.map(e => `${e.track_name} (${e.cm_track})`).join(', '),
      top_playlists_by_diff: e.TOP_PLAYLISTS_BY_FOLLOWERS_DIFF_RANK.map(
        e => `${e.name} (${e.playlist_id})`
      ).join(', '),
      top_playlists_by_percent_diff: e.TOP_PLAYLISTS_BY_FOLLOWERS_PERCENT_DIFF_RANK.map(
        e => `${e.name} (${e.playlist_id})`
      ).join(', '),
    }));
  };

  return (
    <>
      <Title
        title={
          type === 'followers'
            ? 'Top keywords by change in playlist follower total over past week'
            : 'Top keywords by change in playlist count over past week'
        }
        description="By percentage and by absolute diff"
      />
      <TreeMapChart
        data={
          data?.map((e, index) => ({
            label: e.TOKEN,
            value: {
              followers: {
                value: e.TOKEN_FOLLOWERS_TODAY,
                diff: e.TOKEN_FOLLOWERS_DIFF,
                percent: e.TOKEN_FOLLOWERS_PERCENT_GROWTH,
              },
              playlists: {
                value: e.TOKEN_PLAYLISTS_TODAY,
                diff: e.TOKEN_PLAYLISTS_DIFF,
                percent: e.TOKEN_PLAYLISTS_PERCENT_GROWTH * 100,
              },
            }[type][mode],
          })) ?? []
        }
        prefix={mode === 'diff' ? '+' : ''}
        tooltipPrefix={mode === 'diff' ? '+' : ''}
        suffix={
          {
            diff: '',
            percent: '%',
            value: '',
          }[mode]
        }
        tooltipSuffix={
          {
            diff: `${capitalize(type)} Changed`,
            percent: `% ${capitalize(type)} Changed`,
            value: capitalize(type),
          }[mode]
        }
      />
      <br />
      <Panel
        separator
        title={
          <Flex vertical>
            <Typography.Text type="secondary">
              Weekly Data: {lastMonday} - {today}
            </Typography.Text>
            <br />
            <Flex align="flex-end" gap="small">
              <Selector<Type>
                title="By Change In"
                options={[
                  {
                    label: 'Playlists',
                    value: 'playlists',
                  },
                  {
                    label: 'Followers',
                    value: 'followers',
                  },
                ]}
                value={type}
                onChange={setType}
              />

              <Segmented<Mode>
                value={mode}
                onChange={value => setMode(value)}
                options={[
                  {
                    label: (
                      <Image
                        preview={false}
                        src="https://storage.googleapis.com/cm-app-assets/icons/plusMinus.png"
                        className="fill-neutral-200 relative top-0.5"
                        width={13}
                        height={13}
                      />
                    ),
                    value: 'diff',
                    title: 'Diff',
                  },
                  {
                    label: '%',
                    value: 'percent',
                    title: 'Percent',
                  },
                  {
                    label: '123',
                    value: 'value',
                    title: 'Value',
                  },
                ]}
              />
            </Flex>
          </Flex>
        }
        extra={
          <CSVLink
            data={getCSVData()}
            headers={CSV_FORMAT}
            filename={`Trending Keywords - ${type} - ${mode}.csv`}
          >
            <Button type="primary" icon={<FAIcon name="farFileCsv" />}>
              Download CSV
            </Button>
          </CSVLink>
        }
      >
        <AntdTable<TrendingKeywordsItem & { position: number }>
          columns={columns}
          data={data?.map((e, index) => ({
            ...e,
            position: index + 1,
          }))}
          loading={isLoading}
          extraData={{ mode }}
          onChange={(pagination, filters, sorter) => {
            if (sorter) {
              setSortedInfo(sorter as SorterResult<any>);
            }
          }}
        />
      </Panel>
    </>
  );
};

export default TrendingKeywords;
