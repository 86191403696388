import React from 'react';
import { PLATFORM_COLORS, PLATFORM_DARK_COLORS } from '@Constants/platform/colors';
import { getPlatformFromString } from '@Constants/platform/utils';
import { BrandIcon } from '@Shared/Style';
import { StyleUtil } from '@Utils';
import { numericalScaleFormatter } from '@Utils/number';
import { Flex, Segmented, Skeleton, Switch, Typography } from 'antd';
import { HorizontalBar, Doughnut } from 'react-chartjs-2';
import {
  LinkCompletenessStatisticsType,
  useGetLinkCompletenessStatistics,
  useGetLinkCompletenessStatisticsHistory,
} from '@Services/linkCompleteness';
import { LinkCompletenessStatsProps } from './Statistics';
import { SUPPORT_PLATFORMS } from '../../constants';
import { ChartOptions } from 'chart.js';

const SingleBarChart = ({ platform, unlinked, linked }) => {
  const backgroundColor = StyleUtil.darkenColor(PLATFORM_DARK_COLORS[platform.toLowerCase()], 10);
  const label = getPlatformFromString(platform, true);

  const data = {
    labels: [label!],
    responsive: true,
    offset: true,
    datasets: [
      {
        label: 'Linked',
        data: [linked],
        backgroundColor,
        stack: 'Stack 0',
      },
      {
        label: 'Unlinked',
        data: [unlinked],
        backgroundColor: 'rgba(126, 126, 126, 0.192)',
        stack: 'Stack 0',
      },
    ],
  };

  const options: ChartOptions = {
    layout: {
      padding: {
        bottom: 40,
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
      },
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function (dataLabel, index) {
              return (Number(dataLabel) / (unlinked + linked)) * 100 + '%';
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
          },
          stacked: true,
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: (item, data) => {
          return `${data.datasets![item.datasetIndex!].label}: ${
            ((Number(item.value) / (unlinked + linked)) * 100).toFixed(1) + '%'
          }`;
        },
      },
      bodyFontSize: 14,
      titleFontSize: 14,
      bodySpacing: 4,
    },
  };

  return (
    <div>
      <Flex gap="small" align="center" justify="space-between">
        <Flex gap="small" align="center">
          <BrandIcon platform={platform.toLowerCase()} style={{ width: 20, height: 20 }} />
          <Typography.Text style={{ fontSize: 16 }} strong>
            {label}
          </Typography.Text>
        </Flex>
        <Typography.Text style={{ fontSize: 16 }} type="secondary">
          {((linked / (unlinked + linked)) * 100).toFixed(2)}%
        </Typography.Text>
      </Flex>
      <div style={{ width: '300px', height: '125px' }}>
        <HorizontalBar data={data} options={options} />
      </div>
    </div>
  );
};

const DonutChart = ({ overall, complete, type }) => {
  const data = {
    labels: type === 'linked' ? ['Not Reviewed', 'Reviewed'] : ['Not Verified', 'Verified'],

    datasets: [
      {
        label: 'Score',
        data: [overall - complete, complete],
        backgroundColor: ['rgba(126, 126, 126, 0.192)', 'rgba(3, 111, 198, 0.2)'],
        borderColor: ['rgba(126, 126, 126, 0.192)', 'rgb(3, 111, 198)'],
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions = {
    tooltips: {
      mode: 'index',
      intersect: false,
      bodyFontSize: 14,
      titleFontSize: 14,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + 'em sans-serif';
        ctx.textBaseline = 'top';
        var text = `${((complete / overall) * 100).toFixed(2)}%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <Doughnut
      data={data}
      height={300}
      width={300}
      options={options}
      plugins={plugins}
      key={complete / overall}
    />
  );
};

const STAT_MAP = {
  spotify: 'noSpotifyURLCount',
  youtube: 'noYoutubeURLCount',
  instagram: 'noInstagramURLCount',
  tiktok: 'noTiktokURLCount',
  pandora: 'noPandoraURLCount',
  shazam: 'noShazamURLCount',
};

const Current = ({ state, target }: LinkCompletenessStatsProps) => {
  const [type, setType] = React.useState<LinkCompletenessStatisticsType>('linked');
  const { data: stats, isLoading } = useGetLinkCompletenessStatistics(
    {
      data: {
        tier: state.tier,
      },
      path: {
        target,
      },
    },
    {
      disable: state.tier?.length === 0,
    }
  );

  const overall =
    type === 'verified' && target === 'artist'
      ? stats?.verifiedLinkCount?.total
      : stats?.overallCount;
  const complete =
    type === 'verified' && target === 'artist'
      ? stats?.verifiedLinkCount?.verified
      : stats?.completedCount;

  return isLoading ? (
    <Skeleton.Input active style={{ width: '100%', height: 300 }} />
  ) : (
    <Flex align="center" justify="space-around">
      <div className="relative">
        {target === 'artist' && (
          <Segmented
            value={type}
            onChange={value => {
              setType(value as LinkCompletenessStatisticsType);
            }}
            options={[
              {
                label: 'Linked',
                value: 'linked',
              },
              {
                label: 'Verified',
                value: 'verified',
              },
            ]}
            className="relative -top-[60px]"
          />
        )}
        <div className="relative -top-10">
          <DonutChart overall={overall} complete={complete} type={type} />
        </div>
      </div>
      <Flex wrap gap="middle" justify="center" style={{ width: '60%' }}>
        {Object.entries(STAT_MAP)
          .filter(([platform]) => SUPPORT_PLATFORMS[target].includes(platform))
          .map(([platform, key]) => (
            <SingleBarChart
              platform={platform}
              unlinked={stats?.[key]}
              linked={stats?.overallCount! - stats?.[key]!}
            />
          ))}
      </Flex>
    </Flex>
  );
};

export default React.memo(Current);
