import React from 'react';
import { connect } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import { Input, Select, Checkbox, DatePicker, Button, Flex } from 'antd';
import { isEmpty } from 'lodash';
import * as actions from '../../../actions';
import { getFormattedDateString } from '../../../utilities';
import { Panel, Table, AntdIcon } from '../../../shared/Style';

const TYPE_OPTIONS = [
  { value: '-', key: '-', label: 'Any type' },
  { value: 'indie', key: 'indie', label: 'Indie' },
  { value: 'major', key: 'major', label: 'Major' },
];
class LabelCorrection extends React.Component {
  state = {
    originalLabel: '',
    revisedLabel: '',
    flagAudiobooks: false,
    ignoreOriginalLabel: false,
    ignoreBefore: '',
    subLabel: '',
    type: '-',
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  handleChangeSelect = name => value => {
    this.setState({ [name]: value });
  };

  handleChangeCheckbox = ({ target: { name, checked } }) => {
    this.setState({ [name]: checked });
  };

  handleDateChange = date => this.setState({ ignoreBefore: date });

  handleSubmit = () => {
    const { flagAudiobooks, ignoreBefore, originalLabel, revisedLabel, subLabel, type } =
      this.state;

    this.props
      .addLabelCorrection({
        flagAudiobooks,
        ignoreBefore: isEmpty(ignoreBefore) ? undefined : getFormattedDateString(ignoreBefore),
        ignoreOriginalLabel: isEmpty(ignoreBefore),
        originalLabel: originalLabel.trim(),
        revisedLabel: revisedLabel.trim(),
        subLabel: subLabel.trim(),
        type: type === '-' ? undefined : type,
      })
      .then(() => {
        this.setState({
          originalLabel: '',
          revisedLabel: '',
          flagAudiobooks: true,
          ignoreOriginalLabel: false,
          ignoreBefore: '',
          subLabel: '',
          type: '-',
        });
      });
  };

  render() {
    const { response } = this.props;

    const {
      flagAudiobooks,
      ignoreBefore,
      ignoreOriginalLabel,
      originalLabel,
      revisedLabel,
      subLabel,
      type,
    } = this.state;
    return (
      <Table
        title="Create new data in label clean-up"
        panelStyle
        layout="horizontal"
        options={[
          {
            span: 4,
            label: 'Original Label *',
            component: (
              <Input
                labelPosition="left"
                name="originalLabel"
                onChange={this.handleChange}
                value={originalLabel}
              />
            ),
          },
          {
            span: 4,
            label: 'Revised Label *',
            component: (
              <Input
                labelPosition="left"
                name="revisedLabel"
                onChange={this.handleChange}
                value={revisedLabel}
              />
            ),
          },
          {
            span: 4,
            label: 'Sub Label',
            component: (
              <Input
                title="Sub Label"
                name="subLabel"
                onChange={this.handleChange}
                value={subLabel}
              />
            ),
          },
          {
            span: 4,
            label: 'Type *',
            component: (
              <Select
                name="type"
                onChange={this.handleChangeSelect('type')}
                options={TYPE_OPTIONS}
                value={type}
              />
            ),
          },
          {
            span: 4,
            label: 'Ignore Before',
            component: <DatePicker value={ignoreBefore} onChange={this.handleDateChange} />,
          },
          {
            span: 4,
            label: 'Flag for audiobooks',
            component: (
              <Checkbox
                name="flagAudiobooks"
                onChange={this.handleChangeCheckbox}
                checked={flagAudiobooks}
              >
                Flag for audiobooks
              </Checkbox>
            ),
          },
        ]}
        extra={
          <Flex justify="center">
            <Button
              disabled={!originalLabel || !revisedLabel}
              icon={<AntdIcon name="edit" />}
              loading={response.isFetching}
              onClick={this.handleSubmit}
              className="blue"
            >
              Submit
            </Button>
          </Flex>
        }
      />
    );
  }
}

const mapStateToProps = state => ({ response: state.metadata.add });
export default connect(mapStateToProps, actions)(LabelCorrection);
