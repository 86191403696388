import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Select, Flex, Card, Input, Button, Popconfirm, Modal } from 'antd';
import Subscription from '../Subscription';

import * as actions from '../../../../actions';
import { AntdIcon, Panel, ResponsiveSection, Table, WhatsThis } from '../../../../shared/Style';
import {
  useSendVerifyEmail,
  useUnblockLast2FA,
  useUpdateUserEmail,
  useUpdateUserName,
} from '../../../../services/user';

import { generateInternalLink, showInfoMessage } from '../../../../utilities';
import { Toast } from 'react-toastify/dist/components';
import { useModal } from '@Hooks';
import { useUserProfile } from '..';
import { capitalize } from 'lodash';
import ModalButton from '@Shared/Style/ModalButton';
import FAIcon from '@Shared/Style/FAIcon';

// TODO: refresh check
const BlockUserButton = connect(
  null,
  actions
)(({ blocked, q, blockUser, unblockUser }) => {
  const [blockedUser, setBlockedUser] = useState(blocked);

  const handleClick = () => {
    let promise;
    if (blockedUser) {
      promise = unblockUser(q);
    } else {
      promise = blockUser(q);
    }
    promise.then(() => setBlockedUser(old => !old));
  };

  return (
    <Button
      icon={<AntdIcon name="close" />}
      className={blockedUser ? 'green' : 'red'}
      onClick={handleClick}
    >
      {blockedUser ? 'Click to unblock' : 'Click to block'} User
    </Button>
  );
});

const DeleteUserButton = connect(
  null,
  actions
)(({ q, deleteUser }) => (
  <Popconfirm
    title="Are you sure you want to delete this user?"
    onConfirm={() => {
      deleteUser(q);
    }}
  >
    <Button danger icon={<AntdIcon name="delete" />}>
      Delete User
    </Button>
  </Popconfirm>
));

const VerifyUserEmailButton = connect(
  null,
  actions
)(({ q, verifyUserEmail, fetchUserInfo }) => {
  const handleClick = () => verifyUserEmail(q).then(() => fetchUserInfo(q));
  return (
    <Button
      icon={<AntdIcon name="checkCircle" />}
      className="green"
      onClick={handleClick}
      title="Verify User Email"
    >
      Verify
    </Button>
  );
});

function UnblockLast2FAButton({ id }) {
  const { execute, isLoading } = useUnblockLast2FA();

  const handleClick = () => {
    execute({ path: { id } });
  };
  return (
    <Button
      className="green"
      icon={<AntdIcon name="checkCircle" />}
      onClick={handleClick}
      loading={isLoading}
    >
      Verify Last 2FA Device And Unblock
      <WhatsThis>
        When user fails to login with 2FA, the user is blocked. This will unblock the user
      </WhatsThis>
    </Button>
  );
}

const SendVerifyEmailButton = connect(
  null,
  actions
)(({ q }) => {
  const { execute, isLoading } = useSendVerifyEmail();
  return (
    <Button
      loading={isLoading}
      icon={<AntdIcon name="send" />}
      className="green"
      onClick={() => execute({ data: { email: q } })}
    >
      Send Verify Email
    </Button>
  );
});

const GrantUserAPIAccessButton = connect(
  null,
  actions
)(({ q, grantAPIAccess, fetchUserInfo, token }) => {
  const { refetch } = useUserProfile();

  const handleClick = () => grantAPIAccess(q).then(() => refetch());
  return (
    <Button className="green" icon={<AntdIcon name="send" />} onClick={handleClick}>
      {token ? 'Send To User' : 'Generate'}
    </Button>
  );
});

const RemoveUserAPIAccessButton = connect(
  null,
  actions
)(({ q, removeAPIAccess, fetchUserInfo }) => {
  const { refetch } = useUserProfile();
  const handleClick = () => removeAPIAccess(q).then(() => refetch());

  return (
    <Button danger icon={<AntdIcon name="delete" />} onClick={handleClick}>
      Delete Refresh Token
    </Button>
  );
});

const RemoveSSOLoginButton = connect(
  null,
  actions
)(({ q, removeSSOLogin, fetchUserInfo }) => {
  const handleClick = () => removeSSOLogin(q).then(() => fetchUserInfo(q));
  return (
    <Button danger icon={<AntdIcon name="delete" />} onClick={handleClick}>
      Remove 3th party
    </Button>
  );
});

const AddTrustedUserButton = connect(
  null,
  actions
)(({ q, addTrustedUser, fetchUserInfo }) => {
  const handleClick = () => addTrustedUser(q && q.toString()).then(() => fetchUserInfo(q));
  return (
    <Button className="green" icon={<AntdIcon name="checkCircle" />} onClick={handleClick}>
      Add To Trusted User
    </Button>
  );
});
const ResetArtistPlanSelectionButton = connect(
  null,
  actions
)(({ userId, resetArtistPlanSelection }) => {
  const handleClick = () => resetArtistPlanSelection(userId);
  return (
    <Button danger icon={<AntdIcon name="undo" />} onClick={handleClick}>
      Reset Artist Plan Selection
    </Button>
  );
});

const ResetPasswordPasswordButton = connect(
  null,
  actions
)(({ q, resetUserPassword }) => {
  const handleClick = () => resetUserPassword(q);
  return (
    <Button danger icon={<AntdIcon name="undo" />} onClick={handleClick}>
      Reset User Password (chartmetric!)
    </Button>
  );
});

const ChangeUserEmailButton = ({ id, email }) => {
  const { refetch } = useUserProfile();
  const { execute, isLoading } = useUpdateUserEmail();

  const handleSubmit = () => {
    if (email !== newEmail) {
      execute({
        data: {
          id,
          email: newEmail,
        },
      }).then(() => refetch(newEmail));
    }
  };
  const [newEmail, setNewEmail] = useState(email);
  return (
    <ModalButton
      onOk={handleSubmit}
      title="Change User Email"
      Button={<Button loading={isLoading} icon={<AntdIcon name="edit" />} />}
    >
      <Input placeholder="New Email" value={newEmail} onChange={e => setNewEmail(e.target.value)} />
    </ModalButton>
  );
};

const ChangeUserNameButton = ({ id, name }) => {
  const { refetch } = useUserProfile();
  const { execute, isLoading } = useUpdateUserName();
  const [newName, setNewName] = useState(name);

  const handleSubmit = () => {
    if (name !== newName) {
      execute({
        data: {
          id,
          name: newName,
        },
      })
        .then(() => showInfoMessage('Successfully changed user name.'))
        .then(() => refetch());
    }
  };
  return (
    <Flex align="center" justify="space-between">
      {name}
      <ModalButton
        onOk={handleSubmit}
        title="Change User Name"
        Button={<Button loading={isLoading} icon={<AntdIcon name="edit" />} />}
      >
        <Input placeholder="New Email" value={newName} onChange={e => setNewName(e.target.value)} />
      </ModalButton>
    </Flex>
  );
};

function Info({
  id,
  name,
  email,
  blocked,
  reason = 'Unspecified',
  customerInfo,
  register_date,
  image_url,
  verified,
  trusted,
  inTeam,
  teamInfo,
  refreshToken,
  source,
  ip_addresses,
  churnInfo,
}) {
  const span = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 1,
    xxl: 1,
    xl: 6,
  };

  return (
    <ResponsiveSection>
      <Panel title="Information">
        <Table
          title="Basic Info"
          options={[
            {
              span,
              label: 'User Name',
              component: <ChangeUserNameButton name={name} id={id} />,
              labelIcon: 'tag',
            },
            {
              span,
              label: 'User Email',
              labelIcon: 'mail',
              component: (
                <Flex justify="space-between" align="center" gap="small">
                  {email}
                  <ChangeUserEmailButton email={email} id={id} />
                </Flex>
              ),
            },
            {
              span,
              label: 'User ID',
              component: (
                <Flex align="center" justify="space-between">
                  {id}
                  <Button
                    icon={<AntdIcon name="copy" />}
                    onClick={() => {
                      navigator.clipboard.writeText(id);
                      showInfoMessage('Copied!');
                    }}
                  />
                </Flex>
              ),
              labelIcon: 'id',
            },
            {
              span,
              label: 'Image',
              component: <img src={image_url} style={{ width: '100%' }} />,
              labelIcon: 'image',
            },
            {
              span,
              label: 'Joined at',
              value: moment(register_date).format('l'),
              labelIcon: 'calendar',
            },
            {
              span,
              label: 'Verified Email',
              component: (
                <Flex justify="space-between" gap={12} align="center">
                  <FAIcon color="green" name={verified && 'fasCheckCircle'} />
                  <VerifyUserEmailButton q={id} />
                </Flex>
              ),
              labelIcon: 'checkCircle',
            },
            {
              span,
              label: 'Trusted',
              component: <FAIcon color="green" name={trusted && 'fasCheckCircle'} />,
            },
            {
              span,
              label: 'Signed up with',
              component: (
                <Flex wrap="wrap" gap={8} align="center" justify="space-between">
                  {capitalize(source) || 'EMAIL'}
                  {source && <RemoveSSOLoginButton q={id} />}
                </Flex>
              ),

              labelIcon: 'mail',
            },
            {
              span,
              label: 'Refresh Token',
              description: 'User should have API plan to use this token',
              component: (
                <Flex gap={8} wrap align="center" justify="space-between">
                  {!refreshToken.refresh_token && 'No refresh token'}
                  {refreshToken.refresh_token && (
                    <Button
                      icon={<AntdIcon name="copy" />}
                      onClick={() => {
                        navigator.clipboard.writeText(refreshToken.refresh_token);
                        showInfoMessage('Copied!');
                      }}
                    />
                  )}
                  <GrantUserAPIAccessButton q={id} token={refreshToken.refresh_token} />
                </Flex>
              ),
              labelIcon: 'data',
            },
            {
              span,
              label: 'Blocked',
              component: (
                <Flex gap={12} justify="space-between" align="center">
                  <FAIcon
                    color={blocked ? 'red' : 'green'}
                    name={blocked ? 'fasCheckCircle' : 'farXmark'}
                  />
                  {blocked && `This user is currently blocked (Reason: ${reason})`}
                  <BlockUserButton blocked={blocked} reason={reason} q={id} />
                </Flex>
              ),
              labelIcon: 'close',
            },
          ]}
        />
        {teamInfo?.managerEmail && (
          <Table
            title="Team Info"
            extra={
              <Button
                href={generateInternalLink(`purchase/team-manager`, {
                  q: email,
                })}
                type="link"
                target="_blank"
              >
                See details
              </Button>
            }
            options={[
              {
                span,
                label: 'Team Role',
                value:
                  teamInfo &&
                  (teamInfo.managerEmail === email
                    ? 'Account Manager'
                    : teamInfo.admin
                    ? 'ADMIN'
                    : 'MEMBER'),
                labelIcon: 'tag',
              },
              {
                span,
                label: 'Manager Email',
                value: teamInfo && teamInfo.managerEmail,
                labelIcon: 'mail',
              },
            ]}
          />
        )}
      </Panel>
      <Subscription email={email} customerInfo={customerInfo} churnInfo={churnInfo} userId={id} />

      <Panel title="Actions">
        <Flex wrap="wrap" gap="small">
          {!trusted && <AddTrustedUserButton q={id} />}
          <ResetArtistPlanSelectionButton userId={id} />
          <UnblockLast2FAButton id={id} />
          <SendVerifyEmailButton q={email} />
          <ResetPasswordPasswordButton q={email} />
          <RemoveUserAPIAccessButton q={id} />
          <DeleteUserButton q={id} />
        </Flex>
      </Panel>

      {ip_addresses && (
        <Panel title="Recent IP Addresses">
          <Flex vertical align="center">
            <ul>
              {ip_addresses.map(e => (
                <li>{e}</li>
              ))}
            </ul>
          </Flex>
        </Panel>
      )}
    </ResponsiveSection>
  );
}

export default Info;
