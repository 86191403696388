import { Divider, Tag, Flex, Badge } from 'antd';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import ReactTable from 'react-table';
import {
  useGetClaimRequestStatus,
  useGetClaimRequest,
  useApproveClaimRequest,
  useDeclineClaimRequest,
} from '../../../../services/request';
import { getFormattedLocalDate } from '../../../../utilities';
import useRequestTable from '../../../../hooks/useRequstTable';
import { DataTable, ActionInput, Panel } from '../../../../shared/Style';
import SearchInput from '../../../../shared/Table/SearchInput';
import RequestSubmitButton from '../../../../shared/Table/SubmitModal';
import { CLAIM_COLUMNS, CLAIM_SUBMIT_MODAL_COLUMNS } from './columns';
import { REASON_TEMPLATES } from './constants';
import { AntdTable } from '@Shared';

const SEARCH_FILTERS = [
  {
    label: 'User Email',
    value: 'user_email',
  },
  {
    label: 'Verification Code',
    value: 'verification_code',
  },
];

function CheckClaimStatus() {
  const [checkInputValue, setCheckInputValue] = useState('');
  const { refetch, isLoading, data } = useGetClaimRequestStatus({
    path: { code: checkInputValue },
  });

  return (
    <Panel title="Check Claim Status" icon="farCircleCheck">
      <ActionInput
        icon="fadTag"
        size="middle"
        placeholder="Put verification code to check status"
        value={checkInputValue}
        onChange={e => setCheckInputValue(e.target.value)}
        action={{
          icon: 'fasCheckCircle',
          content: 'Check',
          loading: isLoading,
          onClick: () => refetch(),
        }}
      />
      {data &&
        data.map(claim =>
          claim ? (
            <>
              <Divider />
              <Tag
                color={
                  claim.status === 'accepted'
                    ? 'success'
                    : claim.status === 'denied'
                    ? 'error'
                    : 'default'
                }
              >
                {capitalize(claim.status)}
              </Tag>
              {claim.status === 'pending' ? (
                'Requested '
              ) : (
                <>
                  by <b>{claim.reviewer_email}</b>
                </>
              )}
              at
              <b> {getFormattedLocalDate(claim.modified_at)}</b>
            </>
          ) : (
            'Not found'
          )
        )}
    </Panel>
  );
}

function ClaimRequest() {
  const {
    data,
    isLoading,
    onClickApprove,
    onClickDecline,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    onSearch,
    refetch,
    selected,
    state,
  } = useRequestTable(useGetClaimRequest, null);

  const [reasonTemplates, setReasonTemplates] = useState(REASON_TEMPLATES);
  const { execute: approve, isLoading: isApproving } = useApproveClaimRequest();
  const { execute: decline, isLoading: isDeclining } = useDeclineClaimRequest();
  const [reasons, setReasons] = useState({});

  const onClickSubmit = async () => {
    await Promise.all([
      ...state.approves.map(id => approve({ path: { id } })),
      ...state.declines.map(id =>
        decline({
          path: { id },
          data: { reason: reasons[id] ?? '' },
        })
      ),
    ]);
    refetch();
  };

  const handleAddReasonOption = (reason: string) => {
    setReasonTemplates([...reasonTemplates, reason]);
  };

  const onChangeReason = (id: number, reason: string) => {
    setReasons({ ...reasons, [id]: reason });
  };

  return (
    <>
      <CheckClaimStatus />
      <Panel
        icon="farHand"
        extra={<SearchInput filters={SEARCH_FILTERS} originalData={data} onChange={onSearch} />}
        title={
          <Flex align="center" gap="small">
            Claim Request
            <Badge count={data?.length ?? '0'} color="blue" overflowCount={9999} />
          </Flex>
        }
      >
        <AntdTable
          key="table"
          loading={isLoading}
          data={!state.filteredData ? data : state.filteredData}
          columns={CLAIM_COLUMNS({
            onClickApprove,
            onClickDecline,
            approves: state.approves,
            declines: state.declines,
            onClickSelectApproveAll,
            onClickSelectDeclineAll,
            data,
          })}
        />
        <Divider />
        <Flex justify="center" gap="small">
          <RequestSubmitButton
            columns={CLAIM_SUBMIT_MODAL_COLUMNS({
              declines: state.declines,
              onChangeReason,
              reasons,
              handleAddReasonOption,
              reasonTemplates,
            })}
            onClickSubmit={onClickSubmit}
            approveRequest={state.approves}
            declineRequest={state.declines}
            data={data ?? []}
            loading={isApproving || isDeclining}
            selected={selected}
          />
        </Flex>
      </Panel>
    </>
  );
}

export default React.memo(ClaimRequest);
