import SearchInput from '@Shared/Table/SearchInput';
import { Flex, Typography } from 'antd';
import React from 'react';

interface Props {
  title: string;
  description?: string;
  extra?: React.ReactNode;
}

const Title = ({ title, description, extra }: Props) => {
  return (
    <Flex justify="space-between" gap="small" align="center" className="my-4">
      <Flex vertical>
        <Typography.Title level={4}>{title}</Typography.Title>
        {description && <Typography.Text type="secondary">{description}</Typography.Text>}
      </Flex>
      <div>{extra}</div>
    </Flex>
  );
};

export default Title;
