import * as XLSX from 'xlsx';

export async function parseExcel<T extends string>(
  file: File,
  columns: T[]
): Promise<Partial<Record<T, string>>[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      if (!e.target?.result) {
        reject(new Error('Cannot read file.'));
        return;
      }

      const data = new Uint8Array(e.target.result as ArrayBuffer);
      let workbook;
      let worksheet;

      if (file.name.endsWith('.csv')) {
        const csvString = new TextDecoder().decode(data);
        const csvData = csvString.split('\n').map(row => row.split(','));
        worksheet = XLSX.utils.aoa_to_sheet(csvData);
      } else {
        workbook = XLSX.read(data, { type: 'array' });
        worksheet = workbook.Sheets[workbook.SheetNames[0]];
      }

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const [headers, ...rows] = jsonData;

      const filteredData = rows.map(row => {
        const filteredRow: Partial<Record<T, string>> = {};
        columns.forEach(column => {
          const columnIndex = (headers as any).indexOf(column);
          if (columnIndex > -1) {
            filteredRow[column] = (row as any)[columnIndex];
          }
        });
        return filteredRow;
      });

      resolve(filteredData);
    };

    reader.readAsArrayBuffer(file);
  });
}

export function csvToXlsx(csvData: string, sheetName: string = 'Sheet1'): XLSX.WorkBook {
  const worksheet = XLSX.utils.aoa_to_sheet(csvData.split('\n').map(row => row.split(',')));

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

  return workbook;
}

export function saveAsXlsx(workbook: XLSX.WorkBook, fileName: string): void {
  XLSX.writeFile(workbook, fileName);
}
