import { useAddTeamMember, useGetTeamMembers } from '@Services/team';
import { AntdTable, GlobalSearch } from '@Shared';
import { AntdIcon } from '@Shared/Style';
import ModalButton from '@Shared/Style/ModalButton';
import { Button, Flex, Modal, Segmented, Typography } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import AddBulkMember from './AddBulkMember';
import { useModal } from '@Hooks';
import FAIcon from '@Shared/Style/FAIcon';
import { CSVLink } from 'react-csv';

const CSV_FORMAT = [
  { label: 'Email', key: 'email' },
  { label: 'Status', key: 'status' },
  { label: 'Reason', key: 'reason' },
];

const AddMemberModal = ({
  managerId,
  managerEmail,
}: {
  managerId: number;
  managerEmail: string;
}) => {
  const { execute: addTeamMember, isLoading: isLoadingAdd } = useAddTeamMember();
  const [q, setQ] = React.useState('');
  const [emails, setEmails] = React.useState<string[]>([]);
  const [type, setType] = React.useState<'singular' | 'bulk'>('singular');
  const { showModal, closeModal, modalProps } = useModal();

  const [bulkResult, setBulkResult] = React.useState<
    { email: string; status: boolean; reason?: string }[]
  >([]);

  const { refetch } = useGetTeamMembers(
    {
      data: { q: managerEmail },
    },
    {
      disable: isEmpty(managerEmail),
    }
  );

  useEffect(() => {
    setBulkResult([]);
  }, [managerEmail]);

  const getCSVData = () => {
    return bulkResult.map(({ email, status, reason }) => ({
      email,
      status: status ? 'Success' : 'Failed',
      reason,
    }));
  };

  return (
    <>
      <ModalButton
        title="Add Member"
        confirmLoading={isLoadingAdd}
        okButtonProps={{
          loading: isLoadingAdd,
          disabled: type === 'singular' ? isEmpty(q) : emails.length === 0,
        }}
        onOk={async () => {
          if (type === 'singular') {
            await addTeamMember({
              data: {
                memberEmail: q,
                teamManagerUserId: managerId,
              },
            });
            refetch();
          } else {
            setBulkResult([]);

            await Promise.all(
              emails.map(email =>
                addTeamMember({
                  data: {
                    memberEmail: email,
                    teamManagerUserId: managerId,
                  },
                })
                  .then(res => setBulkResult(prev => [...prev, { email, status: true }]))
                  .catch(e => {
                    return setBulkResult(prev => [
                      ...prev,
                      { email, status: false, reason: e.response.data.error },
                    ]);
                  })
              )
            ).then(() => {
              refetch();
              showModal();
            });
          }
        }}
        Button={
          <Button icon={<AntdIcon name="plus" />} className="green">
            Add Member
          </Button>
        }
      >
        <Typography.Text type="secondary">
          User who has not signed up for Chartmetric can also be added, and when they sign up, user
          will automatically be assigned to the team
        </Typography.Text>
        <br />
        <Segmented
          className="mt-4 mb-3"
          options={[
            {
              label: 'Singular',
              value: 'singular',
            },
            {
              label: 'Bulk (Excel)',
              value: 'bulk',
            },
          ]}
          value={type}
          onChange={(value: 'singular' | 'bulk') => setType(value)}
        />
        {type === 'singular' ? (
          <GlobalSearch
            types={['user']}
            onChange={setQ}
            icon="user"
            size="middle"
            variant="filled"
          />
        ) : (
          <AddBulkMember emails={emails} setEmails={setEmails} />
        )}
      </ModalButton>
      <Modal
        title={`Bulk Add Member Result (${bulkResult.length})`}
        okButtonProps={{
          style: { display: 'none' },
        }}
        cancelButtonProps={{
          style: { display: 'none' },
        }}
        open={modalProps.show}
        onClose={closeModal}
        style={{
          minWidth: '70%',
        }}
      >
        <AntdTable
          data={bulkResult}
          columns={[
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Status',
              accessor: 'status',
              Cell: ({ value, original }) => (
                <Typography.Text type={value ? 'success' : 'danger'}>
                  {value ? (
                    <FAIcon name="fasCheckCircle" color="green" />
                  ) : (
                    `Failed: ${original.reason}`
                  )}
                </Typography.Text>
              ),
            },
          ]}
        />
        <Flex justify="flex-end" className="-mb-4 mt-4" gap="small">
          <Button
            onClick={() => {
              closeModal();
              setBulkResult([]);
            }}
          >
            Close
          </Button>
          <CSVLink
            data={getCSVData()}
            filename={`Bulk Add Member Result ${new Date().toLocaleDateString()}.csv`}
          >
            <Button className="green" icon={<FAIcon name="farFileCsv" />}>
              Download CSV
            </Button>
          </CSVLink>
        </Flex>
      </Modal>
    </>
  );
};

export default AddMemberModal;
