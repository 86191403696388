import Home from 'Routers/Home';
import ITEMS, { MenuItem } from './items';
import { FAIcons } from '@Shared/Style/FAIcon';
import { ENTITY_ICON } from '@Constants/entity';
import { ROUTE_PATH } from './path';

export const MENUS: MenuType[] = [
  {
    name: 'Home',
    icon: 'fadHouse',
    route: ROUTE_PATH.HOME,
    component: Home,
    items: [],
  },
  {
    name: 'Keywords',
    icon: 'fadSquareK',
    route: ROUTE_PATH.KEYWORDS,
    items: ITEMS.KEYWORDS,
  },
  {
    name: 'Onesheet',
    icon: 'fadInfoCircle',
    route: ROUTE_PATH.ONESHEET,
    items: ITEMS.ONESHEET,
  },
  {
    name: 'User Manager',
    icon: 'fadUser',
    route: ROUTE_PATH.USER,
    items: ITEMS.USER,
  },
  {
    name: 'Request Manager',
    icon: 'fadTableList',
    route: ROUTE_PATH.REQUESTS,
    items: ITEMS.REQUESTS,
  },
  {
    name: 'Entity Data',
    icon: 'fadDatabase',
    route: ROUTE_PATH.CMDATA,
    items: ITEMS.CM_DATA,
  },
  {
    name: 'Linking',
    icon: 'fadLink',
    route: ROUTE_PATH.LINKING,
    items: ITEMS.LINKING,
  },
  {
    name: 'Artist',
    icon: ENTITY_ICON.ARTIST,
    route: ROUTE_PATH.ARTIST,
    items: ITEMS.ARTIST,
  },
  {
    name: 'Track',
    icon: ENTITY_ICON.TRACK,
    route: ROUTE_PATH.TRACK,
    items: ITEMS.TRACK,
  },

  {
    name: 'Genre & Mood',
    icon: ENTITY_ICON.GENRE,
    route: ROUTE_PATH.TAG,
    items: ITEMS.TAG,
  },
  {
    name: 'Label',
    icon: ENTITY_ICON.LABEL,
    route: ROUTE_PATH.LABEL,
    items: ITEMS.LABEL,
  },
  {
    name: 'Marketing',
    icon: 'fadHeart',
    route: ROUTE_PATH.CAMPAIGN,
    items: ITEMS.MARKETING,
  },
  {
    name: 'Purchase',
    icon: 'fadCreditCard',
    route: ROUTE_PATH.PURCHASE,
    items: ITEMS.PURCHASE,
  },
  {
    name: 'Restriction',
    icon: 'fadBan',
    route: ROUTE_PATH.RESTRICTION,
    items: ITEMS.RESTRICTION,
  },
  {
    name: 'Utility',
    icon: 'fadWrench',
    route: ROUTE_PATH.UTILITY,
    items: ITEMS.UTILITY,
  },

  {
    name: 'Logs',
    icon: 'fadHistory',
    route: ROUTE_PATH.LOGS,
    items: ITEMS.LOGS,
  },
];

export interface MenuType {
  name: string;
  icon: FAIcons;
  route: string;

  component?: () => React.JSX.Element;
  items: MenuItem[];
}

export const enumerateMenus = () =>
  MENUS.reduce<MenuType[]>((all, menu) => {
    all.push(menu);

    return all;
  }, []);
