export const ROUTE_PATH = {
  HOME: '/',
  ONESHEET: '/onesheet',
  USER: '/user',
  REQUESTS: '/requests',
  CMDATA: '/cmdata',
  ARTIST: '/artist',
  TRACK: '/track',
  LABEL: '/label',
  CAMPAIGN: '/campaign',
  PURCHASE: '/purchase',
  RESTRICTION: '/restriction',
  UTILITY: '/utility',
  LOGS: '/logs',
  LOGIN: '/login',
  TAG: '/tag',
  LINKING: '/linking',
  KEYWORDS: '/keyword',
};


export const generateRoute = (route: string, name: string) =>
  `${route}/${name.toLowerCase().replace('/', '-').replaceAll(' ', '-')}`;