import { ENTITY_ICON } from '@Constants/entity';
import { KeywordDetailTrackItem, useGetKeywordTrackDetails } from '@Services/keywords';
import { AntdTable } from '@Shared';
import { Panel } from '@Shared/Style';
import FAIcon from '@Shared/Style/FAIcon';
import { Column } from '@Shared/Table/AntdTable';
import EntityCell from '@Shared/Table/EntityCell';
import { ordinalSuffixFormatter } from '@Utils/number';
import React from 'react';
import { ENTITY_COLUMNS } from './columns';

interface Props {
  q: string;
}

const Tracks = ({ q }: Props) => {
  const { data: track, isLoading: isLoadingTrack } = useGetKeywordTrackDetails(
    {
      data: {
        q: q && q.replaceAll(' ', '').toLowerCase(),
      },
    },
    {
      disable: !q,
    }
  );

  return (
    <Panel icon={ENTITY_ICON.TRACK} title="Tracks">
      <AntdTable<KeywordDetailTrackItem & { position: number }>
        columns={ENTITY_COLUMNS('track') as any}
        data={track?.map((e, index) => ({ ...e, position: index + 1 })) ?? []}
        showSizeChanger={false}
        pageSize={5}
        loading={isLoadingTrack}
      />
    </Panel>
  );
};

export default Tracks;
