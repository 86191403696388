import React, { useEffect, useRef } from 'react';
import { Button, Card, Divider, Flex, Typography } from 'antd';
import ResponsiveSection from './ResponsiveSection';
import FAIcon, { FAIcons } from './FAIcon';

interface Props {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
  extra?: React.ReactNode;
  innerStyle?: boolean;
  style?: React.CSSProperties;
  icon?: FAIcons;
  className?: string;
  separator?: boolean;
}

function Panel({
  className,
  children,
  title,
  extra,
  innerStyle,
  style,
  description,
  icon,
  separator,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [extraStyle, setExtraStyle] = React.useState<React.CSSProperties | undefined>(undefined);

  useEffect(() => {
    const bodyNodes = ref.current?.querySelector('.ant-card-body')?.children;

    if (
      bodyNodes?.length === 1 &&
      (bodyNodes?.[0].className.startsWith('GlobalSearch') ||
        bodyNodes?.[0].className.startsWith('ActionInput'))
    ) {
      setExtraStyle({ maxWidth: '1300px' });
    }
  }, [ref]);

  return (
    <Card
      ref={ref}
      className={className}
      type={innerStyle ? 'inner' : undefined}
      title={
        <Flex vertical className="w-full" justify="center">
          <Flex align="center" className="w-full" justify="center">
            {innerStyle ? (
              <Typography.Text
                className="w-full"
                style={{ fontWeight: 500, display: 'flex', alignItems: 'center', gap: 4 }}
                type="secondary"
              >
                {icon && <FAIcon name={icon} color="#6f87af" className="pr-1" />}
                {title}
              </Typography.Text>
            ) : (
              <Typography.Text
                className="text-xl w-full"
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  color: '#61666c',
                }}
              >
                {icon && <FAIcon name={icon} color="#6f87af" className="mr-1" fontSize={22} />}
                {title}
              </Typography.Text>
            )}
          </Flex>
          {description && (
            <Typography.Text
              style={{
                fontWeight: 400,
                paddingTop: 10,
                paddingBottom: 10,
              }}
              type="secondary"
            >
              {description}
            </Typography.Text>
          )}
        </Flex>
      }
      extra={extra}
      style={{ ...style, ...extraStyle }}
    >
      {separator && <Divider className="mt-4 mb-5" />}
      {children}
    </Card>
  );
}

function ResponsiveBody({ children }) {
  return <ResponsiveSection>{children}</ResponsiveSection>;
}

Panel.ResponsiveBody = ResponsiveBody;

export default Panel;
