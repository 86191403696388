import { ENTITY_ICON } from '@Constants/entity';
import { KeywordDetailPlaylistItem, useGetKeywordPlaylistDetails } from '@Services/keywords';
import { AntdTable } from '@Shared';
import { Panel } from '@Shared/Style';
import FAIcon from '@Shared/Style/FAIcon';
import { Column } from '@Shared/Table/AntdTable';
import EntityCell from '@Shared/Table/EntityCell';
import { addComma, ordinalSuffixFormatter } from '@Utils/number';
import { Flex, Tag, Typography } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import React, { useState } from 'react';

interface Props {
  q: string;
}

const PLAYLIST_COLUMNS = ({
  sortedInfo,
}: {
  sortedInfo: SorterResult<any>;
}): Column<KeywordDetailPlaylistItem & { position: number }>[] => [
  {
    Header: 'Position',
    accessor: 'position',
    Cell: ({ value }) => (
      <>
        {value <= 3 && (
          <FAIcon
            name="fasCrown"
            color={value === 1 ? 'orange' : value === 2 ? 'silver' : 'brown'}
          />
        )}
        {ordinalSuffixFormatter(value)}
      </>
    ),
    align: 'left',
  },
  {
    Header: (
      <Flex align="center" gap="small">
        <FAIcon name={ENTITY_ICON.PLAYLIST} />
        Playlist
      </Flex>
    ),
    accessor: 'id',
    Cell: ({ value, original }) => (
      <EntityCell
        value={{
          ...original,
          platform: 'spotify',
        }}
        entity="playlist"
      />
    ),
  },
  {
    key: 'followers',
    Header: 'Followers (Past week)',
    accessor: 'followers_last_week',
    Cell: ({ value }) => <>{addComma(value)}</>,
    sorter: true,
    sortOrder: sortedInfo.columnKey === 'followers' ? sortedInfo.order : undefined,
    sortDirections: ['descend', 'ascend'],
  },
  {
    key: 'followers_percent',
    Header: 'Followers % (Past week)',
    accessor: 'followers_percent_diff',
    Cell: ({ value }) => (
      <Tag color={value > 0 ? 'green' : 'red'}>{(value * 100)?.toFixed(2)}%</Tag>
    ),
    sorter: true,
    sortOrder: sortedInfo.columnKey === 'followers_percent' ? sortedInfo.order : undefined,
    sortDirections: ['descend', 'ascend'],
  },
  // {
  //   Header: 'Most Popular Tracks',
  //   accessor: 'most_popular_tracks',
  //   Cell: ({ value }) => (
  //     <EntityOverlapCell
  //       value={value.map(e => ({
  //         ...e,
  //         description: `Playlist adds: ${e.playlist_adds}`,
  //       }))}
  //       entity="track"
  //     />
  //   ),
  // },
  // {
  //   Header: 'Most Frequent Artists',
  //   accessor: 'most_frequent_artists',
  //   Cell: ({ value, original }) => (
  //     <EntityOverlapCell
  //       value={value.map(e => ({
  //         ...e,
  //         description: `Track count: ${e.track_count}`,
  //       }))}
  //       entity="artist"
  //     />
  //   ),
  // },
];

const Playlists = ({ q }: Props) => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({
    columnKey: 'followers',
    order: 'descend',
  });
  const { data: playlist, isLoading: isLoadingPlaylist } = useGetKeywordPlaylistDetails(
    {
      data: {
        q,
        sortBy: sortedInfo.columnKey as 'followers' | 'followers_diff' | 'followers_percent',
      },
    },
    {
      disable: !q,
    }
  );
  return (
    <>
      <Typography.Title level={4}>Top Playlists with Searched Keyword</Typography.Title>
      <Panel>
        <AntdTable<KeywordDetailPlaylistItem & { position: number }>
          columns={PLAYLIST_COLUMNS({ sortedInfo })}
          data={playlist?.map((e, index) => ({ ...e, position: index + 1 })) ?? []}
          loading={isLoadingPlaylist}
          onChange={(pagination, filters, sorter) => {
            if (sorter) {
              setSortedInfo(sorter as SorterResult<any>);
            }
          }}
        />
      </Panel>
    </>
  );
};

export default Playlists;
