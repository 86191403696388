import ClaimedEntityUsersModal from '@Features/RequestManager/Claim/List/ClaimedEntityUsersModal';
import { useGetClaimedEntities } from '@Services/meta';
import { AntdTable } from '@Shared';
import { DataTable, Panel } from '@Shared/Style';
import Country from '@Shared/Style/Country';
import { generateCmUrl } from '@Utils';
import { TARGET_MAPPER } from '@Utils/constants';
import { Flex } from 'antd';
import React from 'react';

type Data = {
  code2: string;
  image_url: string;
  name: string;
  target: string;
  id: number;
};

function Claims({ q }) {
  const { data, isLoading } = useGetClaimedEntities({
    data: {
      search: q,
      limit: 100,
      offset: 0,
      target: 'cm_artist',
    },
  });

  return (
    <Panel title="Claims" icon="farHand">
      <AntdTable<Data>
        loading={isLoading}
        data={data?.data}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ original, value }) => (
              <Flex gap={6} justify="center" align="center">
                <img src={original.image_url} style={{ borderRadius: '50%', width: 32 }} />
                <a
                  href={generateCmUrl(TARGET_MAPPER[original.target], {
                    id: original.id,
                  })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {value}
                </a>
              </Flex>
            ),
          },
          {
            Header: 'Country',
            accessor: 'code2',
            Cell: Country,
          },
          {
            Header: 'Users',
            Cell: ClaimedEntityUsersModal,
          },
        ]}
      />
    </Panel>
  );
}

export default Claims;
