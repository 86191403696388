import React from 'react';
import { KeywordDetailArtistItem, KeywordDetailTrackItem } from '@Services/keywords';
import { Column } from '@Shared/Table/AntdTable';
import FAIcon from '@Shared/Style/FAIcon';
import { ordinalSuffixFormatter } from '@Utils/number';
import EntityCell from '@Shared/Table/EntityCell';

export const ENTITY_COLUMNS = (
  entity: 'artist' | 'track'
): Column<KeywordDetailArtistItem & KeywordDetailTrackItem & { position: number }>[] => [
  {
    Header: 'Position',
    accessor: 'position',
    sorter: true,
    Cell: ({ value }) => (
      <>
        {value <= 3 && (
          <FAIcon
            name="fasCrown"
            color={value === 1 ? 'orange' : value === 2 ? 'silver' : 'brown'}
          />
        )}
        {ordinalSuffixFormatter(value)}
      </>
    ),
    align: 'left',
    width: 25,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value, original }) => <EntityCell value={original} entity={entity} />,
    width: 200,
    align: 'left',
  },
  {
    Header: 'Playlists Added',
    accessor: 'adds',
    width: 25,
  },
];
