import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  TRACKS: '/admin/artist/tracks',
  ALBUMS: '/admin/artist/albums',
  UPDATE_TRACKS: '/admin/artist/tracks/update',
  UPDATE_ALBUMS: '/admin/artist/albums/update',
  LIST: '/admin/artist/list',
  MERGE_ARTIST: '/admin/merge-artists',
  CREATE_ARTIST: '/admin/artist/new',
  ADD_METADATA: '/admin/artist',
};

export const useGetArtistTracks = persistGetToUrl<{
  data: { q: string };
}>(ENDPOINT.TRACKS, {
  parse: data => data.data.tracks,
});

export const useGetArtistAlbums = persistGetToUrl<{
  data: { q: string };
}>(ENDPOINT.ALBUMS);

export const useUpdateArtistTracks = mutateToUrl<{
  data: { q: string; useApi: boolean };
}>('PUT', ENDPOINT.UPDATE_TRACKS);

export const useUpdateArtistAlbums = mutateToUrl<{
  data: { q: string };
}>('PUT', ENDPOINT.UPDATE_ALBUMS);

export interface ArtistListResponseDataItem {
  code2: string;
  id: number;
  image_url: string;
  name: string;
  cm_tags: {
    id: number;
    hidden: boolean;
    link_type: string;
  }[];
}

export const useGetArtistList = persistGetToUrl<
  {
    data: {
      limit: number;
      offset: number;
      tags?: number[];
      code2s?: string[];
    };
  },
  {
    data: ArtistListResponseDataItem[];
    total: number;
  }
>(ENDPOINT.LIST, {
  defaultParams: {
    data: {
      limit: 50,
      offset: 0,
    },
  },
});

export const useMergeArtists = mutateToUrl<{
  query: {
    primaryArtistUrl: string;
    secondaryArtistUrl: string;
  };
}>('PUT', ENDPOINT.MERGE_ARTIST);

export const useCreateArtist = mutateToUrl<{
  data: {
    name: string;
    image_url?: string;
  };
}>('POST', ENDPOINT.CREATE_ARTIST);

export const useAddArtistMetadata = mutateToUrl<{
  data: {
    q: string;
  };
}>('POST', ENDPOINT.ADD_METADATA);