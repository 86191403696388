import React, { Component } from 'react';
import { Panel } from '../../../shared/Style';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';
import { useSendEmailReport } from '@Services/user';

const SendEmailReport = () => {
  const { execute, isLoading } = useSendEmailReport();
  return (
    <Panel title="Send General Email Report Manually" icon="farEnvelope">
      <GlobalSearch
        loading={isLoading}
        icon="user"
        types={['user']}
        onSubmit={email =>
          email &&
          execute({
            data: {
              email: email as string,
            },
          })
        }
        name="send-email-report"
        size="middle"
        buttonIcon="mail"
        buttonLabel="Send"
      />
    </Panel>
  );
};

export default SendEmailReport;
