import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import DatePicker from 'react-datepicker';
import { Button, Flex, Select, Skeleton, Typography } from 'antd';
import { capitalize } from 'lodash';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';
import {
  getFormattedDateString,
  getDateFormatFromDatePicker,
  generateCmUrl,
  getFormattedLocalDate,
  getDateToString,
  getDateFromTimestamp,
} from '@Utils';
import 'react-datepicker/dist/react-datepicker.css';

import { DEFAULT_CHART_PROPS } from './constants';
import { useStates } from '@Hooks';
import { useGetRecentlyVisited, useGetUserSessionCounts } from '@Services/user';
import { Panel } from '@Shared/Style';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import classNames from 'classnames';
import EntityCell from '@Shared/Table/EntityCell';

const COLUMNS: Column<any>[] = [
  {
    Header: 'Name',
    Cell: props => <EntityCell entity={props.original.type} value={props.original} />,
  },
  {
    Header: 'type',
    accessor: 'type',
    formatter: value => capitalize(value),
  },
];

interface Props {
  q: string;
  userId: number;
}

function SessionCounts({ q, userId }: Props) {
  const { setAllStates, states } = useStates<{
    data: any[];
    left: string | number;
    right: string | number;
    refAreaLeft: string;
    refAreaRight: string;
    top: string | number;
    bottom: string | number;
    animation: boolean;
  }>({
    data: [],
    // This is for the zooming graph
    ...DEFAULT_CHART_PROPS,
    animation: true,
  });

  const [userSessionType, setUserSessionType] = useState<'day' | 'minute'>('day');
  const [userSessionDate, setUserSessionDate] = useState(new Date());
  const {
    data: perDayData,
    isLoading: isLoadingPerDay,
    refetch: refetchPerDay,
  } = useGetUserSessionCounts({
    path: { type: 'day' },
    data: {
      email: q,
      date: userSessionDate,
    },
  });

  const {
    data: perMinuteData,
    isLoading: isLoadingPerMinute,
    refetch: refetchPerMinute,
  } = useGetUserSessionCounts(
    {
      path: { type: 'minute' },
      data: {
        email: q,
        date: userSessionDate,
      },
    },
    {
      disable: userSessionType === 'day' || !userSessionDate,
    }
  );

  const isLoading = userSessionType === 'day' ? isLoadingPerDay : isLoadingPerMinute;
  const data = userSessionType === 'day' ? perDayData : perMinuteData;

  const convertData = () => {
    if (isLoading) return [];
    if (userSessionType === 'day') {
      return data
        .map(({ timestp, count }) => ({ name: timestp.split('T')[0].replaceAll('-', '/'), count }))
        .reverse();
    }
    return data.map(({ hm, count }) => ({ name: hm, count }));
  };

  const disabledRecentlyVisited =
    !userId ||
    (userSessionType === 'day' &&
      typeof states.left !== 'number' &&
      typeof states.right !== 'number');

  const {
    data: recentlyVisitedData,
    refetch: getRecentlyVisited,
    isLoading: isLoadingRecentlyVisited,
    isFetching: isFetchingRecentlyVisited,
  } = useGetRecentlyVisited(
    userSessionType === 'day'
      ? {
          path: {
            id: userId,
          },
          data: {
            since: getFormattedDateString(convertData()?.[states.left]?.name),
            until: getFormattedDateString(convertData()?.[states.right]?.name),
          },
        }
      : {
          path: {
            id: userId,
          },
          data: {
            since: getFormattedDateString(userSessionDate),
            until: getFormattedDateString(
              new Date(userSessionDate).setDate(new Date(userSessionDate).getDate() + 1)
            ),
          },
        },
    {
      disable: disabledRecentlyVisited,
    }
  );

  useEffect(() => {
    getRecentlyVisited();
  }, [userSessionDate]);

  const getAxisYDomain = (from, to, ref, offset) => {
    const { data: chartData } = states;

    const refData = chartData.slice(from - 1, to);
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    refData.forEach(d => {
      if (d[ref] > top) top = d[ref];
      if (d[ref] < bottom) bottom = d[ref];
    });

    return [(bottom || 0) - offset, (top || 0) + offset];
  };

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = states;
    const { data: chartData } = states;

    // if dragging not started from the left
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setAllStates({
        refAreaLeft: '',
        refAreaRight: '',
      });
      return;
    }

    let left = chartData.findIndex(d => d['name'] === refAreaLeft);
    let right = chartData.findIndex(d => d['name'] === refAreaRight);

    // dragging leftwards
    if (left > right) {
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
      const temp = left;
      left = right;
      right = temp;
    }

    // retrieving max y value from selected range
    const [bottom, top] = getAxisYDomain(left, right, 'count', 1);

    setAllStates({
      refAreaLeft: '',
      refAreaRight: '',
      data: chartData.slice(left, right + 1),
      left,
      right,
      bottom,
      top,
    });
  };

  const zoomOut = () => {
    setAllStates({
      data: convertData(),
      ...DEFAULT_CHART_PROPS,
    });
  };

  useEffect(() => {
    // refetch();
    if (userId) {
      getRecentlyVisited();
    }
  }, [userSessionType, userSessionDate, states.left, states.right]);

  useEffect(() => {
    setAllStates({ data: convertData() });
  }, [data]);

  const CustomTick = props => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        {payload.value.includes('/') ? (
          <>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" fontSize={12}>
              {payload.value.split('/')[0]}
            </text>
            <text x={0} y={16} dy={16} textAnchor="middle" fill="#666" fontSize={12}>
              {payload.value.split('/')[1] + payload.value.split('/')[2]}
            </text>
          </>
        ) : (
          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" fontSize={12}>
            {payload.value}
          </text>
        )}
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-black rounded-lg px-2 py-1">
          <p className="label text-white font-bold text-lg">{label}</p>
          <p className="label text-white ">Session Counts: {payload[0].value}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <Panel title="User Session Counts" description="Drag a chart to see recently visited data">
      <Flex justify="center" gap="small">
        <Button
          type={userSessionType === 'day' ? 'primary' : 'default'}
          className={userSessionType === 'day' ? 'green' : ''}
          onClick={() => {
            setUserSessionType('day');
            zoomOut();
          }}
        >
          Per Day
        </Button>
        <Select
          value={userSessionType === 'minute' ? getDateToString(userSessionDate) : undefined}
          loading={isLoadingPerDay}
          allowClear
          showSearch
          className={classNames('w-[150px]', {
            'border-green-500 border-2 rounded-lg': userSessionType === 'minute',
          })}
          placeholder="Select Date"
          options={
            perDayData?.map(({ timestp }) => ({
              value: timestp,
              label: timestp.split('T')[0],
            })) || []
          }
          onChange={date => {
            setUserSessionType('minute');
            setUserSessionDate(getDateFromTimestamp(date));
            zoomOut();
          }}
          onClear={() => {
            setUserSessionType('day');
            setUserSessionDate(new Date());
            zoomOut();
          }}
        />
      </Flex>
      <br />
      {!disabledRecentlyVisited && (
        <Flex justify="right">
          <Button onClick={zoomOut}>Zoom Out</Button>
        </Flex>
      )}
      <br />
      {!isLoading && data?.length === 0 ? (
        <Flex justify="center">No data found</Flex>
      ) : isLoading ? (
        <Flex justify="center" className="full-width">
          <Skeleton.Node
            active
            className="full-width"
            style={{
              width: '100%',
              height: '300px',
            }}
          >
            <span />
          </Skeleton.Node>
        </Flex>
      ) : (
        <ResponsiveContainer height={300}>
          <LineChart
            width={400}
            height={400}
            data={states.data}
            syncId="anyId"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 10,
            }}
            onMouseDown={e => setAllStates({ refAreaLeft: e?.activeLabel })}
            onMouseMove={e => states.refAreaLeft && setAllStates({ refAreaRight: e?.activeLabel })}
            // eslint-disable-next-line react/jsx-no-bind
            onMouseUp={zoom}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tick={<CustomTick />} dataKey="name" domain={[states.left, states.right]} />
            <YAxis
              allowDataOverflow
              domain={[states.bottom, states.top]}
              type="number"
              yAxisId="1"
            />
            <Tooltip content={CustomTooltip} />
            <Line
              yAxisId="1"
              type="monotone"
              dataKey="count"
              stroke="#095ffd"
              animationDuration={300}
              strokeWidth={3}
            />

            {states.refAreaLeft && states.refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={states.refAreaLeft}
                x2={states.refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      )}
      <br />
      {((typeof states.left === 'number' && typeof states.right === 'number') ||
        userSessionType === 'minute') && (
        <Flex vertical>
          <Typography.Title level={5}>
            Recently Visited (
            {userSessionType === 'day'
              ? `${convertData()?.[states.left]?.name} ~${convertData()?.[states.right]?.name}`
              : getDateToString(userSessionDate)}
            )
          </Typography.Title>
          <AntdTable
            loading={isLoadingRecentlyVisited || isFetchingRecentlyVisited}
            data={recentlyVisitedData || []}
            columns={COLUMNS}
          />
        </Flex>
      )}
    </Panel>
  );
}

export default SessionCounts;
