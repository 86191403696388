import { usePagination } from '@Hooks';
import React, { useEffect, useMemo } from 'react';
import { TABS } from '../../constants';
import { AntdTable, ExternalLinksPanel, Pagination } from '@Shared';
import { Button, Checkbox, Flex, Spin, Tag, Typography } from 'antd';
import { getLinkCompletenessResponse } from '@Services/linkCompleteness';
import { AntdIcon, BrandIcon, Panel, Table } from '@Shared/Style';
import COLUMNS from './columns';
import { generateCmUrl } from '@Utils';
import { addComma } from '@Utils/number';
import NoteCell from '../Statistics/NoteCell';
import { LinkingPanel } from '../Linking';
import styles from '../../LinkCompleteness.module.scss';
import { useComplete } from '../../hooks';

interface Props extends ReturnType<typeof usePagination> {
  data: getLinkCompletenessResponse;
  isLoading: boolean;
  entity: (typeof TABS)[number];
  cacheKey: any;
}

const CardView = ({
  data,
  handleChangePage: rootHandleChangePage,
  offset: rootOffset,
  limit: rootLimit,
  isLoading,
  entity,
  cacheKey,
}: Props) => {
  const { limit, offset, handleChangePage, handleChangePageSize } = usePagination({
    defaultLimit: 1,
  });
  const item = data?.data[offset - rootOffset];
  const { handleCheck, status } = useComplete(item?.id ?? 0, entity, cacheKey);
  const onChangePage = (page: number) => {
    rootHandleChangePage(Math.floor(page / rootLimit));
    handleChangePage(page);
  };

  useEffect(() => {
    handleChangePage(rootOffset);
  }, []);

  if (isLoading)
    return (
      <Flex vertical align="center" justify="center" style={{ width: '100%', height: '700px' }}>
        <Spin />
      </Flex>
    );

  if (!item) return null;

  return (
    <Flex vertical>
      <div style={{ position: 'relative' }}>
        <Button
          onClick={() => {
            onChangePage(offset - 1);
          }}
          disabled={offset === 0}
          shape="circle"
          className="blue"
          icon={<AntdIcon name="leftArrow" />}
          style={{ position: 'absolute', top: '50%', left: -40, transform: 'translateY(-50%)' }}
        />
        <Tag
          color={status ? 'blue' : 'red'}
          style={{
            position: 'absolute',
            top: 30,
            right: -40,
            transform: 'translateY(-50%)',
          }}
          onClick={handleCheck}
        >
          <Flex gap="small" align="center">
            <Checkbox checked={status} onClick={handleCheck} />
            {status ? 'Complete' : 'Incomplete'}
          </Flex>
        </Tag>
        <Table
          className={status ? styles.glowBorder : ''}
          layout="horizontal"
          options={[
            {
              label: 'Name',
              component: (
                <Flex gap="small" align="center">
                  <img
                    src={item.image_url}
                    style={{
                      borderRadius: entity === 'artist' ? '50%' : '0%',
                      width: 42,
                      height: 42,
                    }}
                  />
                  <a
                    href={generateCmUrl('artist', { id: item.id })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </a>
                </Flex>
              ),
            },
            {
              label: (
                <Flex align="center" gap="small" justify="center">
                  <BrandIcon platform="chartmetric" />
                  Rank
                </Flex>
              ),
              component: <div>{addComma(item.cm_artist_rank)}</div>,
            },
            {
              label: 'Tier',
              value: item.tier.toString(),
            },
            {
              label: 'Notes',
              component: <NoteCell original={item} value={item.notes} extraData={{ cacheKey }} />,
            },
          ]}
        />
        <Button
          onClick={() => {
            onChangePage(offset + 1);
          }}
          disabled={offset >= data.total}
          shape="circle"
          className="blue"
          icon={<AntdIcon name="rightArrow" />}
          style={{ position: 'absolute', top: '50%', right: -40, transform: 'translateY(-50%)' }}
        />
      </div>
      <Typography.Title level={5}>DSP & Social Links</Typography.Title>
      <LinkingPanel entity={entity} item={item} cacheKey={cacheKey} />
      <Flex justify="end">
        <Pagination
          page={offset}
          pageSize={1}
          onPageChange={onChangePage}
          total={data?.total}
          excludeSizeChanger
        />
      </Flex>
    </Flex>
  );
};

export default React.memo(CardView);
