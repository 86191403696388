import React from 'react';
import { connect } from 'react-redux';
import { showInfoMessage } from '../../../utilities';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';
import { AntdIcon, Panel, Table } from '@Shared/Style';
import { Button, Flex, Form, Input, Typography } from 'antd';
import { useCreateTrack } from '@Services/track';
import { ENTITY_ICON } from '@Constants/entity';
import FAIcon from '@Shared/Style/FAIcon';

const AddNewTrack = () => {
  const { execute, isLoading, data } = useCreateTrack();

  const copyJoinURL = e => {
    navigator.clipboard.writeText(e.target.id);
    showInfoMessage('Copied!');
  };

  return (
    <>
      <Table
        icon="farCirclePlus"
        title="Create New Track"
        panelStyle
        layout="horizontal"
        form={{
          loading: isLoading,
          initialValues: {
            name: '',
            image_url: '',
            isrc: '',
            album_url: '',
            artist_url: '',
          },
          submitPosition: 'bottom',
          onSubmit: values => {
            execute({
              data: {
                name: values.name,
                image_url: values.image_url,
                isrc: values.isrc,
                album_url: values.album_url,
                artist_url: values.artist_url,
              },
            });
          },
        }}
        options={[
          {
            span: 4,
            label: 'Name',
            value: 'name',
            labelIcon: 'farTag',
            component: <Input placeholder="Track's Name." />,
            rules: [{ required: true, message: 'Please input track name!' }],
          },
          {
            span: 4,
            label: 'Image Url',
            value: 'image_url',
            labelIcon: 'farImage',
            component: <Input placeholder="Image URL." />,
            rules: [{ required: true, message: 'Please input image URL!', type: 'url' }],
          },
          {
            span: 4,
            label: 'ISRC',
            value: 'isrc',
            labelIcon: 'farIdCard',
            component: <Input placeholder="ISRC" />,
            rules: [{ required: true, message: 'Please input ISRC!', len: 12 }],
          },
          {
            span: 4,
            label: 'CM Album',
            value: 'album_url',
            labelIcon: ENTITY_ICON.ALBUM,
            component: <GlobalSearch types={['album']} size="middle" />,
            rules: [
              {
                required: true,
                message: 'Please input album URL!',
                type: 'url',
              },
            ],
          },
          {
            span: 4,
            label: 'CM Artist',
            value: 'artist_url',
            labelIcon: ENTITY_ICON.ARTIST,
            component: <GlobalSearch types={['artist']} size="middle" />,
            rules: [
              {
                required: true,
                message: 'Please input artist URL!',
                type: 'url',
              },
            ],
          },
        ]}
      ></Table>
      {data && (
        <Panel title="Result">
          <Flex vertical>
            <div>
              <strong>Track ID:</strong> {data.data.trackId}
            </div>
            <div>
              <strong>Track Link:</strong>{' '}
              <Typography.Link href={data.data.trackLink} target="_blank">
                {data.data.trackLink}
              </Typography.Link>{' '}
              <Button
                id={data.data.trackLink}
                onClick={copyJoinURL}
                className="green"
                icon={<FAIcon name="farCopy" />}
              >
                Copy
              </Button>
            </div>
          </Flex>
        </Panel>
      )}
    </>
  );
};

export default AddNewTrack;
